import { gql } from "@apollo/client";

const DeleteImageInstance = gql`
	mutation DeleteImageInstance($payload: deleteImageInstanceInput!) {
		deleteImageInstance(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default DeleteImageInstance;
