import FavoritesPage from "pages/common/marketplace@dep/Favorites/FavoritesPage";
import Feedback from "pages/member/feedback/Feedback";
import { lazy, Suspense } from "react";
import AuthGuard from "./components/common/AuthGuard";
import StandardLayout from "./components/layout/StandardLayout";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

// The component which allows loading screen while page loading
const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/login/Login")));
const PCALogin = Loadable(lazy(() => import("./pages/authentication/login/PCALogin")));
const InvitePage = Loadable(lazy(() => import("./pages/authentication/invite/InvitePage")));
const PasswordRecovery = Loadable(lazy(() => import("./pages/authentication/passwordRecovery/PasswordRecovery")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/passwordReset/component")));
const Register = Loadable(lazy(() => import("./pages/authentication/register/components")));
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/verifyCode/VerifyCode")));
const SettingsPage = Loadable(lazy(() => import("./pages/common/settings/SettingsPage")));
const MemberReccomendations = Loadable(lazy(() => import("./pages/member/reccomendations/MemberReccomendations")));

// Common Pages
const ProgramViewPage = Loadable(lazy(() => import("./pages/common/programView/ProgramViewPage")));
const ViewEngagement = Loadable(lazy(() => import("./pages/engagement/ViewEngagement")));
const ViewOffering = Loadable(lazy(() => import("./pages/common/marketplace@dep/Offering/Offering")));
const SurveyComponent = Loadable(lazy(() => import("components/SurveyComponent")));
const ViewTeamPage = Loadable(lazy(() => import("pages/common/teamView")));

// Admin Pages
const AdminClientView = Loadable(lazy(() => import("./pages/admin/clientView/AdminClientView")));
const AdminFulfilmentQueue = Loadable(lazy(() => import("./pages/admin/fulfilmentQueue/AdminFulfilmentQueueList")));
const AdminHome = Loadable(lazy(() => import("./pages/admin/home/AdminHome")));
const ViewUser = Loadable(lazy(() => import("./pages/common/userView/ViewUser")));
const ViewProvider = Loadable(lazy(() => import("./pages/common/marketplace/viewProviderNEW/ViewProvider")));
const StoreAdmin = Loadable(lazy(() => import("pages/admin/StoreAdmin")));
const ViewDataImport = Loadable(lazy(() => import("pages/admin/StoreAdmin/components/ViewDataImport")));
const UserManagement = Loadable(lazy(() => import("pages/admin/UserManagement")));

// Client Pages
const ClientDashboard = Loadable(lazy(() => import("./pages/client/dashboard/ClientDashboard")));
const ClientHome = Loadable(lazy(() => import("./pages/client/home/ClientHome")));
const ClientProgramCreate = Loadable(lazy(() => import("./pages/client/programCreate/ClientProgramCreate")));
const ClientAdmin = Loadable(lazy(() => import("./pages/client/manage")));

// Prescriber pages
const ClientV2Home = Loadable(lazy(() => import("pages/clientV2/home/ClientV2Home")));
const ClientV2Manage = Loadable(lazy(() => import("pages/clientV2/manage")));
const ClientV2Purchases = Loadable(lazy(() => import("pages/clientV2/purchases/ClientV2Purchases")));
const ViewUserV2 = Loadable(lazy(() => import("pages/clientV2/ViewUserV2/ViewUserV2")));

// Member pages
const MemberHome = Loadable(lazy(() => import("./pages/member/home/MemberHome")));
const MemberProgramList = Loadable(lazy(() => import("./pages/member/programsList/MemberProgramsList")));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Marketplace pages
const FilterPage = Loadable(lazy(() => import("./pages/common/marketplace/filter/FilterPage")));
const MarketplaceHome = Loadable(lazy(() => import("pages/common/marketplace/home/MarketplaceHome")));

// Provider pages
const ProviderHome = Loadable(lazy(() => import("./pages/provider/home/ProviderHome")));

// Other pages
const ViewPlaylistPage = Loadable(lazy(() => import("./pages/common/playlistView/ViewPlaylist")));

const routes = [
	{
		path: "/",
		children: [
			{
				index: true,
				element: (
					// <GuestGuard>
					<Login />
					// </GuestGuard>
				),
			},
			{
				path: "login/pca",
				element: (
					// <GuestGuard>
					<PCALogin />
					// </GuestGuard>
				),
			},
			{
				path: "invite",
				element: <InvitePage />,
			},
			{
				path: "feedback/:eid",
				element: <Feedback />,
			},
			{
				path: "401",
				element: <AuthorizationRequired />,
			},
			{
				path: "404",
				element: <NotFound />,
			},
			{
				path: "500",
				element: <ServerError />,
			},
			{
				path: "*",
				element: <NotFound />,
			},
			{
				path: "login-unguarded",
				element: <Login />,
			},
			{
				path: "password-recovery",
				element: <PasswordRecovery />,
			},
			{
				path: "password-reset",
				element: <PasswordReset />,
			},
			{
				path: "register",
				element: (
					// <GuestGuard>
					<Register />
					// </GuestGuard>
				),
			},
			{
				path: "register-unguarded",
				element: <Register />,
			},
			{
				path: "verify-code",
				element: <VerifyCode />,
			},
		],
	},

	// ADMIN ROUTES
	{
		path: "/admin",
		element: (
			<AuthGuard>
				<StandardLayout type="admin" />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <AdminHome />,
			},
			{
				path: "survey",
				element: <SurveyComponent />,
			},

			{
				path: "settings",
				element: <SettingsPage />,
			},
			{
				path: "users",
				children: [
					{
						index: true,
						element: <UserManagement />,
					},
					{
						path: "client",
						children: [
							{
								path: ":cid",
								element: <AdminClientView goBack="users" />,
							},
							{
								path: "program/:id",
								element: <ProgramViewPage goBack="users" />,
							},
							{
								path: "engagement/:eid",
								element: <ViewEngagement />,
							},
							{
								path: "team/:tid",
								element: <ViewTeamPage />,
							},
							{
								path: "create",
								element: <ClientProgramCreate />,
							},
							{
								path: "employee/:id",
								element: <ViewUserV2 />,
							},
						],
					},
					{
						path: "fulfilment",
						element: <AdminFulfilmentQueue />,
					},
					{
						path: ":id",
						element: <ViewUser />,
					},
				],
			},
			{
				path: "marketplace",
				children: [
					{
						index: true,
						element: <MarketplaceHome />,
					},
					{
						path: "manage",
						children: [
							{
								index: true,
								element: <StoreAdmin />,
							},
							{
								path: "import/:id",
								element: <ViewDataImport />,
							},
						],
					},
					{
						path: "search",
						element: <FilterPage type="admin" />,
					},
					{
						path: "provider/:id",
						element: <ViewProvider />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
					{
						path: "playlist/:pid",
						element: <ViewPlaylistPage />,
					},
					{
						path: "favourites",
						element: <FavoritesPage />,
					},
				],
			},
		],
	},
	// MEMBER ROUTES
	{
		path: "member",
		element: (
			<AuthGuard>
				<StandardLayout type="member" />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <MemberHome />,
			},
			{
				path: "settings",
				element: <SettingsPage />,
			},
			{
				path: "engagement/:eid",
				element: <ViewEngagement />,
			},
			{
				path: "recommendations",
				element: <MemberReccomendations />,
			},
			{
				path: "program",
				element: <MemberProgramList />,
			},
			{
				path: "marketplace",
				children: [
					{
						index: true,
						element: <MarketplaceHome />,
					},
					{
						path: "search",
						element: <FilterPage />,
					},
					{
						path: "provider/:id",
						element: <ViewProvider />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
					{
						path: "favourites",
						element: <FavoritesPage />,
					},
				],
			},
		],
	},
	// CLIENT ROUTES
	{
		path: "client",
		element: (
			<AuthGuard>
				<StandardLayout type="client" />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <ClientHome />,
			},
			{
				path: "settings",
				element: <SettingsPage />,
			},
			{
				path: "dashboard",
				element: <ClientDashboard />,
			},
			{
				path: "programs",
				element: <MemberProgramList />,
			},
			{
				path: "manage",
				children: [
					{
						index: true,
						element: <ClientAdmin />,
					},
					{
						path: "user/:id",
						element: <ViewUser />,
					},
					{
						path: "engagement/:eid",
						element: <ViewEngagement />,
					},
					{
						path: "program/:id",
						element: <ProgramViewPage type="client" />,
					},
					{
						path: "playlist/:pid",
						element: <ViewPlaylistPage />,
					},
				],
			},
			{
				path: "marketplace",
				children: [
					{
						index: true,
						element: <MarketplaceHome />,
					},
					{
						path: "search",
						element: <FilterPage />,
					},
					{
						path: "provider/:id",
						element: <ViewProvider />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
					{
						path: "playlist/:pid",
						element: <ViewPlaylistPage />,
					},
					{
						path: "favourites",
						element: <FavoritesPage />,
					},
				],
			},
		],
	},
	// PRESCRIPTIVE CLIENT ROUTES
	{
		path: "clientv2",
		element: (
			<AuthGuard>
				<StandardLayout type="clientV2" />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <ClientV2Home />,
			},
			{
				path: "settings",
				element: <SettingsPage />,
			},
			{
				path: "purchases",
				element: <ClientV2Purchases />,
			},
			{
				path: "programs",
				element: <MemberProgramList />,
			},
			{
				path: "manage",
				children: [
					{
						index: true,
						element: <ClientV2Manage />,
					},
					{
						path: "user/:id",
						element: <ViewUserV2 />,
					},
					{
						path: "engagement/:eid",
						element: <ViewEngagement />,
					},
					{
						path: "program/:id",
						element: <ProgramViewPage type="client" />,
					},
					{
						path: "playlist/:pid",
						element: <ViewPlaylistPage />,
					},
				],
			},
			{
				path: "marketplace",
				children: [
					{
						index: true,
						element: <MarketplaceHome />,
					},
					{
						path: "search",
						element: <FilterPage />,
					},
					{
						path: "provider/:id",
						element: <ViewProvider />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
					{
						path: "playlist/:pid",
						element: <ViewPlaylistPage />,
					},
					{
						path: "favourites",
						element: <FavoritesPage />,
					},
				],
			},
		],
	},
	// PROVIDER ROUTES
	{
		path: "provider",
		element: (
			<AuthGuard>
				<StandardLayout type="provider" />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <ProviderHome />,
			},
			{
				path: "manage",
				children: [
					{
						path: ":id",
						element: <ViewProvider />,
					},
					{
						path: "engagement/:eid",
						element: <ViewEngagement />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
				],
			},
			{
				path: "marketplace",
				children: [
					{
						index: true,
						element: <FilterPage />,
					},
					{
						path: "search",
						element: <FilterPage />,
					},
					{
						path: "provider/:id",
						element: <ViewProvider />,
					},
					{
						path: "offering/:id",
						element: <ViewOffering />,
					},
					{
						path: "favourites",
						element: <FavoritesPage />,
					},
				],
			},
		],
	},
];

export default routes;
