/* eslint-disable no-use-before-define */
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";

const renderNavItems = ({ depth = 0, items, pathname }) => (
	<List disablePadding>
		{items.reduce(
			(acc, item) =>
				reduceChildRoutes({
					acc,
					item,
					pathname,
					depth,
				}),
			[]
		)}
	</List>
);

const partialMatch = (item, pathname) => {
	const check = !!matchPath(
		{
			path: item.path,
			end: false,
		},
		pathname
	);

	if (check && item.title === "Home" && pathname.split("/").length === 2) {
		return true;
	}
	if (check && item.title !== "Home") {
		return true;
	}
	return false;
};

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
	const key = `${item.title}-${depth}`;

	if (item.children) {
		acc.push(
			<NavItem
				active={partialMatch(item, pathname)}
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				open={partialMatch}
				path={item.path}
				title={item.title}>
				{renderNavItems({
					depth: depth + 1,
					items: item.children,
					pathname,
				})}
			</NavItem>
		);
	} else {
		acc.push(
			<NavItem
				active={partialMatch(item, pathname)}
				depth={depth}
				icon={item.icon}
				info={item.info}
				key={key}
				path={item.path}
				title={item.title}
			/>
		);
	}

	return acc;
};

const NavSection = (props) => {
	const { items, pathname, title, ...other } = props;

	return (
		<List
			subheader={
				<ListSubheader
					disableGutters
					disableSticky
					sx={{
						color: "text.primary",
						fontSize: "0.75rem",
						lineHeight: 2.5,
						fontWeight: 700,
						textTransform: "uppercase",
					}}>
					{title}
				</ListSubheader>
			}
			{...other}>
			{renderNavItems({
				items,
				pathname,
			})}
		</List>
	);
};

NavSection.propTypes = {
	items: PropTypes.array,
	pathname: PropTypes.string,
	title: PropTypes.string,
};

export default NavSection;
