/* eslint-disable no-unused-vars */
import { Close } from "@mui/icons-material";
import { Box, Card, CardMedia, Fab, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const variants2 = {
	open: { opacity: 1, scale: 1 },
	closed: { opacity: 0, scale: 0 },
};

const ImpersonationCard = ({ activeUser, stopImpersonation }) => {
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setExpanded(true);
		}, 2000);
		setTimeout(() => {
			setExpanded(false);
		}, 10000);
	}, []);

	return (
		<Box
			onMouseEnter={() => setExpanded(true)}
			onMouseLeave={() => setExpanded(false)}
			position="fixed"
			sx={{ p: 2, bottom: 0, zIndex: 99999 }}>
			<motion.div
				style={{ originX: 0 }}
				initial={{ scale: 0 }}
				transition={{
					scale: { duration: 0.2 },
				}}
				animate={expanded ? "open" : "closed"}
				variants={variants}>
				<Card sx={{ justifyContent: "row", display: "flex" }}>
					<CardMedia component="img" image={activeUser?.displayPic} sx={{ width: 80 }} />
					<Box display="flex" alignItems="center" justifyContent="center" sx={{ pl: 2, pb: 1 }}>
						<Box>
							<Typography color="textSecondary" variant="overline" sx={{ mr: 3 }}>
								Browsing as
							</Typography>
							<Typography color="textPrimary" variant="body1" sx={{ mr: 3 }}>
								{activeUser.firstName} {activeUser.lastName}
							</Typography>
							<Typography color="textSecondary" variant="body2" sx={{ mr: 3 }}>
								{activeUser?.clientAccount?.name}
							</Typography>
						</Box>
						<Box sx={{ pl: 3 }} />
						<Fab color="secondary" size="medium" sx={{ mr: 2 }} onClick={() => stopImpersonation()}>
							<Close />
						</Fab>
					</Box>
				</Card>
			</motion.div>
			<motion.div
				style={{ position: "absolute", bottom: 32 }}
				transition={{ opacity: { duration: 0.2 }, scale: { duration: 0.2 } }}
				animate={!expanded ? "open" : "closed"}
				variants={variants2}>
				<Fab color="secondary" size="medium" sx={{ mr: 2 }} onClick={() => stopImpersonation()}>
					<Close />
				</Fab>
			</motion.div>
		</Box>
	);
};

export default ImpersonationCard;
