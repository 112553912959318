/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ApolloClientProvider } from "./apollo/apolloClientProvider";
import App from "./App";
import "./config/theme/toastStyles.css";
import { AuthProvider } from "./contexts/AuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import "./fonts/styles.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<StrictMode>
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ApolloClientProvider>
				<HelmetProvider>
					<BrowserRouter>
						<AuthProvider>
							<CookiesProvider>
								<SettingsProvider>
									<App />
								</SettingsProvider>
							</CookiesProvider>
						</AuthProvider>
					</BrowserRouter>
				</HelmetProvider>
			</ApolloClientProvider>
		</LocalizationProvider>
	</StrictMode>,
	document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
