import { Box, Typography } from "@mui/material";
import { adminMenuTop, clientMenu, memberMenu, providerMenu, clientV2Menu } from "components/layout/menus";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router";

const NavLinks = ({ type = "admin" }) => {
	const navigate = useNavigate();
	const { activeUser } = useAuth();

	return (
		<>
			{type === "admin" && (
				<Box flexDirection="row" display="flex" sx={{ height: "100%" }}>
					{adminMenuTop.map((menuItem, index) => (
						<Box key={index}>
							<Box className="navlink" sx={{ px: 2, pt: 2.5 }}>
								<Typography
									onClick={() => navigate(menuItem.path)}
									variant="body2"
									gutterBottom
									sx={{ cursor: "pointer" }}>
									{menuItem.title}
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
			)}
			{type === "client" && (
				<Box flexDirection="row" display="flex" sx={{ height: "100%" }}>
					{clientMenu.map((menuItem) => (
						<Box sx={{ px: 2, pt: 2.5 }}>
							<Typography
								onClick={() => navigate(menuItem.path)}
								variant="body2"
								gutterBottom
								sx={{ cursor: "pointer" }}>
								{menuItem.title}
							</Typography>
						</Box>
					))}
				</Box>
			)}
			{type === "clientV2" && (
				<Box flexDirection="row" display="flex" sx={{ height: "100%" }}>
					{clientV2Menu.map((menuItem) => (
						<Box sx={{ px: 2, pt: 2.5 }}>
							<Typography
								onClick={() => navigate(menuItem.path)}
								variant="body2"
								gutterBottom
								sx={{ cursor: "pointer" }}>
								{menuItem.title}
							</Typography>
						</Box>
					))}
				</Box>
			)}
			{type === "member" && (
				<Box flexDirection="row" display="flex" sx={{ height: "100%" }}>
					{memberMenu.map((menuItem) => (
						<Box sx={{ px: 2, pt: 2.5 }}>
							<Typography
								onClick={() => navigate(menuItem.path)}
								variant="body2"
								gutterBottom
								sx={{ cursor: "pointer" }}>
								{menuItem.title}
							</Typography>
						</Box>
					))}
					{activeUser?.teamsManaging?.length > 0 && (
						<Box sx={{ px: 2, pt: 2.5 }}>
							<Typography
								// onClick={() => navigate(menuItem.path)}
								variant="body2"
								gutterBottom
								sx={{ cursor: "pointer" }}>
								My Teams
							</Typography>
						</Box>
					)}
				</Box>
			)}
			{type === "provider" && (
				<Box flexDirection="row" display="flex" sx={{ height: "100%" }}>
					{providerMenu.map((menuItem) => (
						<Box sx={{ px: 2, pt: 2.5 }}>
							<Typography
								onClick={() => navigate(menuItem.path)}
								variant="body2"
								gutterBottom
								sx={{ cursor: "pointer" }}>
								{menuItem.title}
							</Typography>
						</Box>
					))}
				</Box>
			)}
		</>
	);
};

export default NavLinks;
