import ChartPie from "icons/ChartPie";
import ChartSquareBarIcon from "icons/ChartSquareBar";
import ShoppingBagIcon from "icons/ShoppingBag";
import { Favorite, Home } from "@mui/icons-material";
import Users from "icons/Users";
// eslint-disable-next-line no-unused-vars
import Star from "icons/Star";

export const adminMenu = [
	{
		title: "Dashboard",
		path: "/admin",
		items: [
			{
				title: "Home",
				path: "/admin",
				icon: <Home fontSize="small" />,
				desc: "Main page containing fulfilment queue and other notifications",
			},
			{
				title: "Fulfilment Queue",
				path: "/admin/fulfilment",
				icon: <ChartSquareBarIcon fontSize="small" />,
				desc: "See and fulfilment purchases made in the store.",
			},
		],
	},
	{
		title: "Marketplace",
		path: "/admin/marketplace",
		items: [
			{
				title: "Marketplace Admin",
				path: "/admin/marketplace/data-admin",
				icon: <ChartSquareBarIcon fontSize="small" />,
				desc: "Manage data imports and manage changes.",
			},
			{
				title: "Browse",
				path: "/admin/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
				desc: "View all offerings and browse the store.",
			},
			{
				title: "Favorites",
				path: "/admin/marketplace/favourites",
				icon: <Favorite fontSize="small" />,
				desc: "Your favorite offerings.",
			},
			{
				title: "Categories",
				path: "/admin/marketplace/categories",
				icon: <ChartSquareBarIcon fontSize="small" />,
				desc: "Edit categories and filtering settings.",
			},
		],
	},
	{
		title: "Users",
		path: "/admin/users",
		items: [
			{
				title: "Clients",
				path: "/admin/clients",
				icon: <Home fontSize="small" />,
				desc: "All clients currently on the platform.",
			},
			{
				title: "Users",
				path: "/admin/users",
				icon: <ChartSquareBarIcon fontSize="small" />,
				desc: "All users, including members, managers and admins.",
			},
		],
	},
];

export const adminMenuTop = [
	{
		title: "Home",
		path: "/admin",
		icon: <Home fontSize="small" />,
	},
	{
		title: "Explore",
		path: "/admin/marketplace",
		icon: <ChartSquareBarIcon fontSize="small" />,
	},
	{
		title: "Store Admin",
		path: "/admin/marketplace/manage",
		icon: <ShoppingBagIcon fontSize="small" />,
	},
	{
		title: "Users",
		path: "/admin/users",
		icon: <Home fontSize="small" />,
	},
	{
		title: "Favourites",
		path: "/admin/marketplace/favourites",
		icon: <Home fontSize="small" />,
	},
];

export const clientMenu = [
	{
		title: "Home",
		path: "/client",
		icon: <Home fontSize="small" />,
	},
	{
		title: "Explore",
		path: "/client/marketplace",
		icon: <ShoppingBagIcon fontSize="small" />,
	},
	{
		title: "Favourites",
		path: "/client/marketplace/favourites",
		icon: <Favorite fontSize="small" />,
	},
	{
		title: "My Purchases",
		path: "/client/programs",
		icon: <Users fontSize="small" />,
	},
	{
		title: "Manage",
		path: "/client/manage",
		icon: <Users fontSize="small" />,
	},
];

export const clientMenuMobile = [
	{
		title: "Home",
		items: [
			{
				title: "Home",
				path: "/client",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Explore",
				path: "/client/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
			{
				title: "Favorites",
				path: "/client/marketplace/favourites",
				icon: <Favorite fontSize="small" />,
			},
			{
				title: "My Purchases",
				path: "/client/programs",
				icon: <Favorite fontSize="small" />,
			},
			{
				title: "Manage",
				path: "/client/manage",
				icon: <ChartPie fontSize="small" />,
			},
		],
	},
];

export const clientV2Menu = [
	{
		title: "Home",
		path: "/clientv2",
		icon: <Home fontSize="small" />,
	},
	{
		title: "Explore",
		path: "/clientv2/marketplace",
		icon: <ShoppingBagIcon fontSize="small" />,
	},
	{
		title: "Purchases",
		path: "/clientv2/purchases",
		icon: <Favorite fontSize="small" />,
	},
	{
		title: "Manage",
		path: "/clientv2/manage",
		icon: <Users fontSize="small" />,
	},
];

export const clientV2MenuMobile = [
	{
		title: "Home",
		items: [
			{
				title: "Home",
				path: "/clientv2",
				icon: <Home fontSize="small" />,
			},
			{
				title: "Explore",
				path: "/clientv2/marketplace",
				icon: <ShoppingBagIcon fontSize="small" />,
			},
			{
				title: "Favorites",
				path: "/clientv2/marketplace/favourites",
				icon: <Favorite fontSize="small" />,
			},
			{
				title: "My Purchases",
				path: "/clientv2/programs",
				icon: <Favorite fontSize="small" />,
			},
			{
				title: "Manage",
				path: "/clientv2/manage",
				icon: <ChartPie fontSize="small" />,
			},
		],
	},
];

export const coachMenu = [
	{
		title: "Home",
		path: "/client",
		icon: <Home fontSize="small" />,
	},
	{
		title: "Engagements",
		path: "/client/dashboard",
		icon: <ChartSquareBarIcon fontSize="small" />,
	},
	{
		title: "My Profile",
		path: "/client/marketplace",
		icon: <ShoppingBagIcon fontSize="small" />,
	},
	{
		title: "Offerings",
		path: "/client/programs",
		icon: <ChartPie fontSize="small" />,
	},
	{
		title: "Invoices",
		path: "/client/people",
		icon: <Users fontSize="small" />,
	},
	{
		title: "Marketplace",
		path: "/client/playlists",
		icon: <Users fontSize="small" />,
	},
	{
		title: "Favorites",
		path: "/client/marketplace/favourites",
		icon: <Favorite fontSize="small" />,
	},
];

export const memberMenuMobile = [
	{
		title: "",
		items: [
			{
				title: "Home",
				path: "/member",
				icon: <Home fontSize="small" />,
			},
			// {
			// 	title: "My Recomendations",
			// 	path: "/member/recommendations",
			// 	icon: <Star fontSize="small" />,
			// },
			{
				title: "My Purchases",
				path: "/member/program",
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
			{
				title: "Browse",
				path: "/member/marketplace",
				icon: <ChartPie fontSize="small" />,
			},
			{
				title: "Favorites",
				path: "/member/marketplace/favourites",
				icon: <Favorite fontSize="small" />,
			},
		],
	},
];

export const memberMenu = [
	{
		title: "Home",
		path: "/member",
		items: [],
		// items: [
		// 	{
		// 		title: "Home",
		// 		path: "/member",
		// 		icon: <Home fontSize="small" />,
		// 	},
		// 	// {
		// 	// 	title: "My Recomendations",
		// 	// 	path: "/member/recommendations",
		// 	// 	icon: <Star fontSize="small" />,
		// 	// },
		// 	{
		// 		title: "My Program",
		// 		path: "/member/program",
		// 		icon: <ChartSquareBarIcon fontSize="small" />,
		// 	},
		// 	{
		// 		title: "Browse",
		// 		path: "/member/marketplace",
		// 		icon: <ChartPie fontSize="small" />,
		// 	},
		// 	// {
		// 	// 	title: "Recomended",
		// 	// 	path: "/client/people",
		// 	// 	icon: <UsersIcon fontSize="small" />,
		// 	// },
		// 	// {
		// 	// 	title: "Activity",
		// 	// 	path: "/member/activity",
		// 	// 	icon: <UsersIcon fontSize="small" />,
		// 	// },
		// ],
	},
	{
		title: "Explore",
		path: "/member/marketplace",
		items: [],
	},
	{
		title: "Favorites",
		path: "/member/marketplace/favourites",
		items: [],
	},
	// {
	// 	title: "Recommended",
	// 	path: "/member/recommendations",
	// 	items: [],
	// },
	{
		title: "My Purchases",
		path: "/member/program",
		items: [],
	},
];

export const providerMenu = [
	{
		title: "Home",
		path: "/provider",
		items: [],
	},
	{
		title: "Offerings",
		path: "/provider/offerings",
		items: [],
	},
	{
		title: "Marketplace",
		path: "/provider/marketplace",
		items: [],
	},
	{
		title: "Favorites",
		path: "/provider/marketplace/favourites",
		items: [],
	},
];
