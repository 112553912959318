import { gql } from "@apollo/client";

const GetProvider = gql`
	query GetProvider($payload: getProviderInput!) {
		getProvider(payload: $payload) {
			... on Provider {
				providerId
				engagements {
					engagementId
					createdAt
					client {
						clientId
						name
						displayPic
						industry
					}
					ProgramTransaction {
						offeringId
						offeringType
						offeringName
						amount
					}
					user {
						firstName
						uid
						lastName
						email
					}
					status
					createdAt
				}
				deleted
				name
				type
				summary
				title
				markup
				description
				status
				location
				offerings {
					offeringId
					providerId
					name
					costPrice
					salePrice
					delivery
					duration

					updatedAt
					createdAt
					coverPic
					type
				}
				offeringCount
				accountName
				bsb
				accountNumber
				bankName
				businessLegalName
				abn
				street
				suburb
				city
				state
				updatedAt
				createdAt
				displayPic
				coverPic
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetProvider;
