import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const LogoBox = ({ firstLine, secondLine, imageUrl }) => (
	<Box sx={{ p: 2 }}>
		<Box
			sx={{
				alignItems: "center",
				backgroundColor: "background.default",
				borderRadius: 1,
				display: "flex",
				overflow: "hidden",
				p: 1,
			}}>
			<Avatar
				src={imageUrl}
				sx={{
					cursor: "pointer",
					height: 60,
					width: 60,
					borderRadius: 1,
				}}
			/>

			<Box sx={{ ml: 2 }}>
				<Typography color="textPrimary" variant="subtitle2">
					{firstLine || ""}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{secondLine}
				</Typography>
			</Box>
		</Box>
	</Box>
);

export default LogoBox;
