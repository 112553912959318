import { useMediaQuery } from "@mui/material";

const Hidden = ({ rules = "visible", children }) => {
	const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
	const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
	const xlUp = useMediaQuery((theme) => theme.breakpoints.up("xl"));

	const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const smDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const mdDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
	const lgDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));
	const xlDown = useMediaQuery((theme) => theme.breakpoints.down("xl"));

	const renderChecker = () => {
		switch (rules) {
			case "all":
				return false;
			case "xsUp":
				return !xsUp;
			case "smUp":
				return !smUp;
			case "mdUp":
				return !mdUp;
			case "lgUp":
				return !lgUp;
			case "xlUp":
				return !xlUp;
			case "xsDown":
				return !xsDown;
			case "smDown":
				return !smDown;
			case "mdDown":
				return !mdDown;
			case "lgDown":
				return !lgDown;
			case "xlDown":
				return !xlDown;
			default:
				return true;
		}
	};

	return <>{renderChecker() ? children : null}</>;
};

export default Hidden;
