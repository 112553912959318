import parse, { domToReact } from "html-react-parser";

const parseHtml = (value) => {
	const parseValue = value || "";
	return parse(parseValue, {
		replace({ children, options, name }) {
			if (name === "ul") {
				return <ul style={{ marginLeft: 15 }}>{domToReact(children, options)}</ul>;
			}
			if (name === "ol") {
				return <ol style={{ marginLeft: 15 }}>{domToReact(children, options)}</ol>;
			}
			return null;
		},
	});
};

export default parseHtml;
