import { gql } from "@apollo/client";

const DeleteFavorite = gql`
	mutation DeleteFavorite($payload: deleteFavoriteInput) {
		deleteFavorite(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default DeleteFavorite;
