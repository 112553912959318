import { gql } from "@apollo/client";

const CreateImageInstance = gql`
	mutation Mutation($payload: createImageInstanceInput!) {
		createImageInstance(payload: $payload) {
			... on ImageInstance {
				imageInstanceId
				downloadUrl
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateImageInstance;
