/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from, Observable } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useCookies } from "react-cookie";
import { onError } from "@apollo/client/link/error";
import firebase from "../config/firebase";

export const graphqlEndpoint = process.env.REACT_APP_BACKEND;

export const ApolloClientProvider = ({ children }) => {
	const [cookies, setCookie] = useCookies(["token"]);
	const token = cookies.token || "";

	const httpLink = createHttpLink({
		uri: graphqlEndpoint,
		credentials: "include",
	});

	const getNewToken = async () =>
		firebase
			.auth()
			.currentUser.getIdToken(true)
			.then((idToken) => idToken);

	const promiseToObservable = (promise) =>
		new Observable((subscriber) => {
			promise.then(
				(value) => {
					if (subscriber.closed) {
						return;
					}
					subscriber.next(value);
					subscriber.complete();
				},
				(err) => {
					subscriber.error(err);
				}
			);
		});

	const retryQuery = (operation, forward) => {
		const oldHeaders = operation.getContext().headers;
		const promise = getNewToken();
		return promiseToObservable(promise).flatMap((newToken) => {
			setCookie("token", newToken, { path: "/" });

			operation.setContext({
				headers: {
					...oldHeaders,
					authorization: `${newToken}`,
				},
			});
			return forward(operation);
		});
	};

	const errorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			if (graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED") return retryQuery(operation, forward);
		}
		if (networkError) {
			return retryQuery(operation, forward);
		}
	});

	const authLink = setContext((_, { headers }) => ({
		headers: {
			...headers,
			authorization: token,
		},
	}));

	const apollo = new ApolloClient({
		link: from([errorHandler, authLink.concat(httpLink)]),
		cache: new InMemoryCache(),
	});

	return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
};
