import { Box, ButtonBase, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import AuthContext from "contexts/AuthContext";
import ArrowRight from "icons/ArrowRight";
import { useContext, useEffect, useRef, useState } from "react";

const ProgramSelector = () => {
	const anchorRef = useRef(null);
	const { activeUser, setActiveProgram, activeProgram } = useContext(AuthContext);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// If the user has a program account, set active program as first one on the list
	useEffect(() => {
		if (activeUser?.programAccounts?.length > 0 && !activeProgram?.programId) {
			setActiveProgram(activeUser?.programAccounts[0].program);
		}
	}, [activeUser]);

	// If no program accounts for user, dont show program selector
	if (activeUser?.programAccounts?.length === 0) {
		return <div />;
	}

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
					pt: 2,
				}}>
				<Typography color="contrast" variant="subtitle2" sx={{ mr: 1 }}>
					{activeProgram ? activeProgram.name : "Select Program"}
				</Typography>
				<KeyboardArrowDown fontSize="small" />
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: "left",
					vertical: "bottom",
				}}
				sx={{ mt: 2, px: 2 }}
				getcontentanchorel={null}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 },
				}}>
				<Box>
					{activeUser?.programAccounts?.length ? (
						<>
							{activeUser?.programAccounts?.map((p) => (
								<MenuItem onClick={() => setActiveProgram(p.program)}>
									<ListItemIcon>
										<ArrowRight fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={
											<Typography color="textPrimary" variant="subtitle2">
												{p?.program?.name}
											</Typography>
										}
									/>
								</MenuItem>
							))}
						</>
					) : (
						<div />
					)}
				</Box>
			</Popover>
		</>
	);
};

export default ProgramSelector;
