import { Box, Card, CardContent, Container, Grid, Link, Switch, Typography } from "@mui/material";
import Logo from "components/common/Logo";
import FormField from "components/FormField/FormField";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import StandardButton from "components/StandardButton/StandardButton";
import useAuth from "hooks/useAuth";
import useIsMountedRef from "hooks/useIsMountedRef";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import showToast from "utils/showToast";

const Login = () => {
	const isMountedRef = useIsMountedRef();
	const [loading, setLoading] = useState(false);
	const [agree, setAgree] = useState(true);
	const { signIn, activeUser } = useAuth();
	const handleSubmit = async (e) => {
		try {
			console.log(e);
			e.preventDefault();
			setLoading(true);
			const auth = await signIn(e.target.email.value, e.target.password.value);
			if (auth.code) {
				throw new Error(auth.message);
			}
			if (isMountedRef.current) {
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
			showToast(err.message, "error");
			setLoading(false);
		}
	};

	// If auth status is unknown, wait for it and show loading
	if (activeUser === null) {
		return <LoadingScreen />;
	}

	return (
		<>
			<Helmet>
				<title>Login | Careerbase</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: "background.default",
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
				}}>
				<Container maxWidth="sm" sx={{ py: "80px" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mb: 8,
						}}>
						<RouterLink to="/">
							<Logo
								sx={{
									height: "auto",
									width: 220,
								}}
								type="login"
							/>
						</RouterLink>
					</Box>
					<Card>
						<CardContent
							sx={{
								display: "flex",
								flexDirection: "column",
								p: 4,
							}}>
							<Box
								sx={{
									alignItems: "center",
									display: "flex",
									justifyContent: "space-between",
									mb: 3,
								}}>
								<div>
									<Typography color="textPrimary" gutterBottom variant="h4">
										Log in
									</Typography>
									<Typography color="textSecondary" variant="body2">
										Please enter your account credentials
									</Typography>
								</div>
							</Box>
							<Box
								sx={{
									flexGrow: 1,
									mt: 3,
								}}>
								<form onSubmit={handleSubmit}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<FormField label="Email" name="email" type="email" required />
										</Grid>
										<Grid item xs={12}>
											<FormField label="Password" name="password" type="password" required />
										</Grid>
										<Grid item xs={12}>
											<Typography color="textSecondary" variant="body2">
												I have read and agree to the{" "}
												<Link href="https://careerbase.co/terms-and-conditions">
													Terms of Service
												</Link>{" "}
												and{" "}
												<Link href="https://careerbase.co/privacy-policy">Privacy Policy</Link>
											</Typography>
											<Switch color="primary" checked={agree} onChange={() => setAgree(!agree)} />
										</Grid>
										<Grid item xs={12}>
											<StandardButton
												disabled={loading || !agree}
												buttonStyle="primary"
												sx={{ width: "100%", ml: 0 }}
												text="Log In"
												type="submit"
											/>
										</Grid>
									</Grid>
								</form>
							</Box>
							<Link
								color="textSecondary"
								component={RouterLink}
								sx={{ mt: 3 }}
								to="/password-recovery"
								variant="body2">
								Forgot password?
							</Link>
						</CardContent>
					</Card>
					<Typography
						color="textSecondary"
						variant="body2"
						sx={{ mt: 1 }}
						display="flex"
						justifyContent="center"
						alignItems="center">
						Careerbase version {process.env.REACT_APP_VERSION}
					</Typography>
				</Container>
			</Box>
		</>
	);
};

export default Login;
