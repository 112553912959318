import { useMutation, useQuery } from "@apollo/client";
import { Box, Card, CardContent, CardHeader, CircularProgress, Dialog, Divider, Fab, Typography } from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import CreateImageInstance from "apollo/mutations/CreateImageInstance";
import CreateImageResource from "apollo/mutations/CreateImageResource";
import DeleteImageInstance from "apollo/mutations/DeleteImageInstance";
import GetImageInstance from "apollo/queries/GetImageInstance";
import StandardButton from "components/StandardButton/StandardButton";
import useIsMountedRef from "hooks/useIsMountedRef";
import firebase from "config/firebase";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "utils/cropImage";
import showToast from "utils/showToast";
import FileDropzone from "../FileDropzone";

const ImageUploadModal = ({
	image,
	open = false,
	onClose,
	imageType = "displayPic",
	ownerId = null,
	ownerType = null,
	refetch = false,
}) => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [loadedImage, setLoadedImage] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [instanceId, setInstanceId] = useState("");
	const [resourceID, setResourceID] = useState("");
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [croppedArea, setCroppedArea] = useState({});
	const [zoom, setZoom] = useState(1);
	const [deleteImage] = useMutation(DeleteImageInstance);
	const [createImageResource] = useMutation(CreateImageResource);
	const [createImageInstance] = useMutation(CreateImageInstance);

	const [editing, setEditing] = useState("");
	const [stage, setStage] = useState("pending");
	const isMounted = useIsMountedRef();

	const { data: imageData } = useQuery(GetImageInstance, {
		variables: { payload: { downloadUrl: image } },
		skip: !image,
	});

	const onCropComplete = useCallback((_, croppedAreaPixels) => {
		setCroppedArea(croppedAreaPixels);
	}, []);

	const resource = imageData?.getImageInstance || null;

	useEffect(() => {
		try {
			if (image && open && isMounted && resource) {
				setStage("edit");
				setEditing("Fetching resource");
				setResourceID(resource?.imageResourceId);

				setResourceID(resource.imageResource?.imageResourceId);

				if (resource.__typename === "Error") {
					setStage("file");
					setEditing("");
				} else {
					setLoadedImage(resource.imageResource.downloadUrl);
					setZoom(parseFloat(resource.zoom));
					setCrop({
						x: parseFloat(resource.cropX),
						y: parseFloat(resource.cropY),
					});

					setTimeout(() => {
						setEditing("");
					}, 750);
				}
			} else if (!image && open && isMounted) {
				setStage("file");
			}
		} catch (error) {
			showToast("Something went wrong. Please refresh try again", "error");
		}
	}, [image, open, isMounted, resource]);

	// UPLOAD FUNCTION. Uploads files to cloud storage. Silent runs the upload without UI feedback.
	// Depended on by uploadResourceInit() & onSave()
	const uploadImage = async (file, ref, silent = false) => {
		if (!silent) setStage("upload");
		const storageRef = firebase.storage().ref();
		const promise = new Promise((resolve, reject) => {
			const uploadTask = storageRef.child(ref).put(file);
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					if (!silent) setUploadProgress(progress);
				},
				(error) => {
					if (!silent) {
						setStage("file");
					}
					reject(error);
				},
				() => {
					uploadTask.snapshot.ref
						.getDownloadURL()
						.then((downloadURL) => {
							if (!silent) {
								setLoadedImage(downloadURL);
								setStage("file");
							}
							resolve(downloadURL);
						})
						.catch((err) => {
							reject(err);
						});
				}
			);
		});
		try {
			const upload = await promise;

			return upload;
		} catch (err) {
			showToast("Could not upload image. Please try again.", "error");

			throw new Error("Could not upload image");
		}
	};

	// Upload the initial resource
	const onFileDrop = async (props) => {
		const file = props[0];
		// const fileName = file.name;
		const fileType = file.type.split("/")[1];
		const fileSize = file.size;
		const maxUploadSize = 10000000; // 10mb max upload size

		try {
			// Makes sure file is not too large
			if (fileSize > maxUploadSize) {
				showToast("Image too large. Please select an image under 10mb", "error");
				throw new Error("Image too large. Please upload an image under 10mb");
			}

			// Handles file validation
			if (fileType === "heic") throw new Error("We do not currently support HEIC files");

			const resourceId = uniqueId();

			const storageRef = `image-service/${imageType}/${resourceId}/source/${Math.floor(Date.now() / 1000)}${
				file.name
			}`;

			// Uploads file to firebase storage
			const downloadUrl = await uploadImage(file, storageRef);

			// Initiates upload resources with backend
			const payload = {
				variables: {
					payload: {
						imageType,
						downloadUrl,
					},
				},
			};
			const req = await createImageResource(payload);
			const data = req.data.createImageResource;
			setResourceID(data.imageResourceId);
			setStage("edit");
			return;
		} catch (err) {
			showToast("Could not drop image. Please try again.", "error");
			setStage("file");
			setLoadedImage("");
		}
	};

	// CREATE NEW INSTANCE FUNCTION
	const onSave = async () => {
		try {
			// Checks that we have the required data to save
			if (!resourceID) throw new Error("Missing resourceId or instanceId (or both)");

			// Crops and optimises image locally
			setEditing("Preparing for upload");
			const newFile = await getCroppedImg(loadedImage, croppedArea, imageType);

			const storageRef = `image-service/${imageType}/${resourceID}/source/${Math.floor(Date.now() / 1000)}`;

			// Uploads file to cloud storage
			setEditing("Uploading...");
			const downloadUrl = await uploadImage(newFile, storageRef, true);

			// Confirms instance with backend
			setEditing("Finishing up");

			const payload = {
				variables: {
					payload: {
						imageResourceId: resourceID,
						downloadUrl,
						cropX: crop.x.toString(),
						cropY: crop.y.toString(),
						zoom: zoom.toString(),
						ownerType,
						ownerId,
					},
				},
			};

			await createImageInstance(payload);
			if (refetch) refetch();

			onClose();
			setEditing("");
			return null;
			// Notifies backend of new instance
		} catch (err) {
			setEditing("");
			showToast("Could not save image. Please try again.", "error");

			return err;
		}
	};

	const deleteInstance = async () => {
		try {
			setEditing("Deleting image");
			if (image) {
				await deleteImage({
					variables: { payload: { downloadUrl: image } },
				});

				if (refetch) refetch();
				setLoadedImage("");
				setInstanceId(null);
				setZoom(0);
				setCrop({ x: 0, y: 0 });
				setResourceID("");
				setStage("file");
				setEditing("");
			} else {
				setLoadedImage("");
				setInstanceId(null);
				setResourceID("");
				setStage("file");
				setZoom(0);
				setCrop({ x: 0, y: 0 });
				setEditing("");
			}
		} catch (error) {
			setEditing("");
			showToast("Could not delete image. Please try again.", "error");
		}
	};

	const cancelUpload = () => {
		onClose();
	};

	return (
		<Dialog maxWidth="lg" open={open}>
			<Card
				elevation={3}
				sx={{
					mx: "auto",
				}}>
				<CardHeader
					title="Upload cover photo"
					action={<Close sx={{ cursor: "pointer" }} onClick={cancelUpload} />}
				/>
				<Divider />
				<CardContent sx={{ width: 650 }}>
					{stage === "file" && <FileDropzone onDrop={onFileDrop} />}

					{stage === "upload" && (
						<Box
							sx={{
								height: 200,
								borderRadius: 1,
								border: 1,
								borderColor: "divider",
								width: "100%",
								justifyContent: "center",
								alignItems: "center",
								display: "flex",
							}}>
							<Box position="relative" display="inline-flex">
								<CircularProgress size={100} variant="determinate" value={uploadProgress} />
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position="absolute"
									display="flex"
									alignItems="center"
									justifyContent="center">
									<Typography variant="body1" component="div" color="textSecondary">
										{`${Math.round(uploadProgress)}%`}
									</Typography>
								</Box>
							</Box>
						</Box>
					)}
					{stage === "edit" && (
						<Box
							sx={{
								height: 400,
								border: 1,
								borderColor: "divider",
								width: "100%",
								position: "relative",
								overflow: "hidden",
								borderRadius: 1,
							}}>
							<Cropper
								image={loadedImage}
								crop={crop}
								zoom={zoom}
								aspect={imageType === "displayPic" ? 4 / 4 : 4 / 1.5}
								onCropChange={setCrop}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
								objectFit={imageType === "displayPic" ? "vertical-cover" : "horizontal-cover"}
							/>
							<Fab onClick={deleteInstance} size="medium" sx={{ position: "absolute", top: 9, right: 9 }}>
								<Delete />
							</Fab>
							{editing && (
								<Box
									sx={{
										height: "100%",
										width: "100%",
										backgroundColor: "rgba(0,0,0,0.6)",
										position: "absolute",
										display: "flex",

										justifyContent: "center",
									}}>
									<Box
										sx={{
											alignSelf: "center",
											display: "flex",
											justifyContent: "center",
											flexDirection: "column",
										}}>
										<Typography
											sx={{
												alignSelf: "center",
												textAlign: "center",
												mb: 2,
												fontWeight: 400,
											}}
											variant="h6"
											component="div"
											color="white">
											{editing}
										</Typography>
										<CircularProgress sx={{ alignSelf: "center" }} size={80} />
									</Box>
								</Box>
							)}
						</Box>
					)}
				</CardContent>
				<Box
					justifyContent="flex-end"
					display="flex"
					sx={{
						px: 2,
						pb: 2,
						pt: 1,
						width: "100%",
					}}>
					<StandardButton onClick={cancelUpload} text="Cancel" buttonStyle="secondary" />
					<StandardButton
						onClick={onSave}
						loading={editing}
						disabled={stage !== "edit" || !loadedImage}
						text={editing ? "Saving" : "Save Changes"}
					/>
				</Box>
			</Card>
		</Dialog>
	);
};

export default ImageUploadModal;
