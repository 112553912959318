/* eslint-disable import/no-unresolved */
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import maintenceMode from "config/maintenceMode";
import useAnalytics from "hooks/useAnalytics";
import { SnackbarProvider } from "notistack";
import MaintenancePage from "pages/Maintence";
import { useEffect } from "react";
import FullStory from "react-fullstory";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./components/common/GlobalStyles";
import createThemes from "./config/theme";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";

LicenseInfo.setLicenseKey(
	"bc517e0778db622db671bf3fde3b6050Tz00NjI1NixFPTE2ODc2ODU5MDQ4MTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const App = () => {
	const content = useRoutes(routes);
	const { settings } = useSettings();
	const { isInitialised, activeUser } = useAuth();
	const { identifyUser } = useAnalytics();

	useScrollReset();

	const theme = createThemes({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme,
	});

	// Set the user and  tie to uid
	useEffect(() => {
		if (activeUser?.uid) identifyUser(activeUser.uid);
	}, [activeUser]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				{!maintenceMode ? (
					<>
						<ToastContainer />
						{/* If production,record in fullstory */}
						{process.env.REACT_APP_AUTH_DOMAIN === "careerbasev3-production.firebaseapp.com" && (
							<FullStory org="15T47K" />
						)}

						<SnackbarProvider dense maxSnack={3}>
							<GlobalStyles />

							{isInitialised.current ? content : <LoadingScreen />}
						</SnackbarProvider>
					</>
				) : (
					<MaintenancePage />
				)}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
