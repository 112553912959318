/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import useIsMountedRef from "hooks/useIsMountedRef";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import showToast from "utils/showToast";
import firebase from "../config/firebase";
// eslint-disable-next-line import/no-cycle
import authGetUser from "./AuthHelpers/authGetUser";
import authGetSSOUser from "./AuthHelpers/authGetSSOUser";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	// USER STATE
	const [cachedUser, setCachedUser] = useState({});
	const [activeUser, setActiveUser] = useState({ type: "MEMBER" });
	const [clientData, setClientData] = useState({});
	const [prescribingUser, setPrescribingUser] = useState({});
	const [activeProgram, setActiveProgram] = useState({});
	const checkImpers = () => {
		const cached = JSON.parse(window.localStorage.getItem("cachedUser"));
		const active = JSON.parse(window.localStorage.getItem("activeUser"));
		if (cached?.uid && cached?.uid !== active?.uid) return true;
		return false;
	};
	const [isImpersonating, setIsImpersonating] = useState(checkImpers());

	const navigate = useNavigate();

	// HANDLES CACHING TO LOCAL STORAGE FOR IMPERSONATION
	useEffect(() => {
		const cached = JSON.parse(window.localStorage.getItem("cachedUser"));
		const active = JSON.parse(window.localStorage.getItem("activeUser"));
		if (cached?.uid !== active?.uid) {
			setCachedUser(cached);
			setActiveUser(active);
			setIsImpersonating(true);
		} else {
			setIsImpersonating(false);
		}
	}, []);

	useEffect(() => {
		window.localStorage.setItem("cachedUser", JSON.stringify(cachedUser));
	}, [cachedUser]);
	useEffect(() => {
		window.localStorage.setItem("activeUser", JSON.stringify(activeUser));
	}, [activeUser]);

	// HANDLES CACHING TO LOCAL STORAGE FOR PRESCRIPTIONS
	useEffect(() => {
		const cachedPresc = JSON.parse(window.localStorage.getItem("prescribingUser"));
		if (cachedPresc?.firstName) {
			setPrescribingUser(cachedPresc);
		}
	}, []);
	useEffect(() => {
		window.localStorage.setItem("prescribingUser", JSON.stringify(prescribingUser));
	}, [prescribingUser]);
	const startPrescribing = async (data, noredir = false) => {
		try {
			setPrescribingUser(data);
			!noredir && navigate("/clientv2/marketplace");
		} catch (error) {
			console.log(error);
		}
	};
	const stopPrescribing = async (noredir = false) => {
		try {
			setPrescribingUser({});
			!noredir && navigate("/clientv2");
			window.localStorage.setItem("prescribingUser", JSON.stringify({}));
		} catch (error) {
			console.log(error);
		}
	};

	// AUTH STATE
	const [userType, setUserType] = useState("");
	const [authLoading, setAuthLoading] = useState(false);
	const [token, setToken] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies(["token"]);

	// GLOBAL AUTH LOADING STATE
	const isInitialised = useIsMountedRef();

	const refetchUser = async () => {
		try {
			if (activeUser?.uid && token) {
				const userRec = await authGetUser(activeUser?.uid, token);
				if (userRec === null) firebase.auth().signOut();
				setActiveUser(userRec);
				setUserType(userRec.type);
				return userRec;
			}
			return null;
		} catch (err) {
			return err;
		}
	};

	useEffect(async () => {
		setAuthLoading(true);
		firebase.auth().onAuthStateChanged(async (user) => {
			try {
				if (user !== null) {
					window.localStorage.setItem("prescribingUser", JSON.stringify({}));
					setIsImpersonating(false);
					const token1 = await user.getIdToken(true);
					// Set emailconfirmed to true
					if (!token1) throw new Error("Could not get token");
					if (token1) setToken(token1);
					setCookie("token", token1, { path: "/" });
					if (!user.uid) throw new Error("Missing uid");
					if (user.uid && token1) {
						const userRec = await authGetUser(user.uid, token1);
						if (userRec === null) firebase.auth().signOut();
						if (userRec.uid && !isImpersonating) {
							setActiveUser(userRec);
							setUserType(userRec.type);
						}
						console.log(userRec?.clientAccount?.subType === "PRESCRIBED");
						if (window.location.pathname === "/" && !isImpersonating) {
							switch (userRec.type) {
								case "ADMIN":
									navigate("/admin");
									break;
								case "MEMBER":
									navigate("/member");
									break;
								case "MANAGER":
									navigate("/client");

									break;
								case "PRESCRIBER":
									navigate("/clientv2");

									break;
								case "PROVIDER":
									navigate("/provider");
									break;
								default:
									navigate("/");
							}
						}
					}
					setAuthLoading(false);
				} else {
					setActiveUser({});
					setAuthLoading(false);
				}
			} catch (err) {
				//
				//
			}
		});
	}, [setToken, setActiveUser, setUserType, setAuthLoading]);

	const signIn = async (email, password) => {
		try {
			if (!email || !password) throw new Error("Missing email or password (or both)");
			console.log("singing");
			const auth = await firebase.auth().signInWithEmailAndPassword(email, password);
			console.log(auth);
			if (auth?.user?.uid) {
				setAuthLoading(true);
				const token1 = await auth.user.getIdToken(true);
				if (token1) setToken(token1);
				setCookie("token", token1, { path: "/" });
				if (auth.user.uid && token1) {
					const userRec = await authGetUser(auth.user.uid, token1);
					setActiveUser(userRec);
					setUserType(userRec.type);
					switch (userRec.type) {
						case "ADMIN":
							navigate("/admin");
							break;
						case "MEMBER":
							{
								if (userRec?.programs.length > 0) setActiveProgram(userRec.programs[0]);
								navigate("/member");
							}
							break;
						case "MANAGER":
							navigate("/client");

							break;
						case "PRESCRIBER":
							navigate("/clientv2");

							break;
						case "PROVIDER":
							navigate("/provider");
							break;
						default:
							navigate("/");
					}
				}
				setAuthLoading(false);
			} else {
				showToast("Could not access user record, please try again.", "error");
			}

			return auth;
		} catch (error) {
			//
			return error;
		}
	};

	const logout = async () => {
		try {
			await firebase.auth().signOut();
			setUserType("");
			setToken("");
			setActiveUser({});
			return null;
		} catch (err) {
			return err;
		}
	};

	const impersonateUser = async ({ uid }) => {
		try {
			setCachedUser(activeUser);
			const user = await authGetUser(uid, token);
			setActiveUser(user);
			console.log("this shouldnt be running");
			if (user.type === "ADMIN") {
				navigate("/admin");
			} else if (user.type === "MANAGER") {
				navigate("/client");
			} else if (user.type === "PROVIDER") {
				navigate("/provider");
			} else {
				if (user?.programs.length > 0) setActiveProgram(user.programs[0]);
				navigate("/member");
			}
			setIsImpersonating(true);
			showToast("You are now impersonating");
		} catch (error) {
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	const stopImpersonation = async () => {
		try {
			const oldActive = activeUser?.uid;
			setActiveUser(cachedUser);

			// Navigate back to the user's people page
			if (cachedUser.type === "ADMIN") {
				navigate(`/admin/users/${oldActive}`);
			} else if (cachedUser.type === "MANAGER") {
				navigate(`/client/manage/user/${oldActive}`);
			} else {
				navigate("/");
			}

			setCachedUser({});
			setIsImpersonating(false);
			showToast("Impersonation finished");
		} catch (error) {
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	const activeDirectory = new firebase.auth.OAuthProvider("microsoft.com");

	const ADSignIn = async () => {
		await firebase
			.auth()
			.signInWithPopup(activeDirectory)
			.then(async (result) => {
				// IdP data available in result.additionalUserInfo.profile.
				// ...
				const { _delegate: user } = result.user;
				const { profile } = result.additionalUserInfo;

				/** @type {firebase.auth.OAuthCredential} */
				// const { credential } = result;

				// OAuth access and id tokens can also be retrieved:
				// const { accessToken, idToken } = credential;
				const { mail } = profile;

				const payload = {
					...profile,
					uid: user.uid,
				};

				delete payload["@odata.context"];
				const token1 = await user.getIdToken(true);
				if (token1) setToken(token1);
				setCookie("token", token1, { path: "/" });

				const userRec = await authGetSSOUser(payload, token1);
				setActiveUser(userRec);
				setUserType(userRec.type);
				setActiveProgram(userRec.programs[0]);
				navigate("/member");

				const isCareerbase = mail.includes("careerbase.co");
				const isPCA = mail.includes("propertycouncil.com.au");
				if (isCareerbase) {
				}
				if (isPCA) {
				}
			})
			.catch((error) => {
				// Handle error.
				console.log(error);
			});
	};

	return (
		<AuthContext.Provider
			value={{
				ADSignIn,
				cachedUser,
				setCachedUser,
				activeUser,
				setActiveUser,
				userType,
				setUserType,
				clientData,
				setClientData,
				signIn,
				isInitialised,
				logout,
				authLoading,
				setAuthLoading,
				token,
				refetchUser,
				setActiveProgram,
				activeProgram,
				impersonateUser,
				stopImpersonation,
				isImpersonating,
				prescribingUser,
				startPrescribing,
				stopPrescribing,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
