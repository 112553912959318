import { gql } from "@apollo/client";

const DeleteRecommendation = gql`
	mutation DeleteRecommendation($payload: deleteRecommendationInput) {
		deleteRecommendation(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default DeleteRecommendation;
