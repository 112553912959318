import {
	Avatar,
	Badge,
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Popover,
	Tooltip,
	Typography,
} from "@mui/material";
// import LinkText from "components/LinkText/LinkText";
import BellIcon from "icons/Bell";
import ChatAltIcon from "icons/ChatAlt";
import Check from "icons/Check";
import CreditCardIcon from "icons/CreditCard";
import ShoppingCartIcon from "icons/ShoppingCart";
import Star from "icons/Star";
import User from "icons/User";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Exclamation from "icons/Exclamation";

const iconsMap = {
	cart: ShoppingCartIcon,
	message: ChatAltIcon,
	card: CreditCardIcon,
	star: Star,
	check: Check,
	user: User,
	alert: Exclamation,
};

const NotificationsPopover = ({ handleMarkAsRead, notifications = [] }) => {
	const navigate = useNavigate();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (!handleMarkAsRead) {
		throw new Error("NotifcationsPopver: handleMarkAsRead is required");
	}

	return (
		<>
			<Tooltip title="Notifications">
				<IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
					<Badge color="error" badgeContent={notifications.filter((n) => n?.read === false).length}>
						<BellIcon fontSize="small" />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: "center",
					vertical: "bottom",
				}}
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 320 },
				}}>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="h6">
						Notifications
					</Typography>
				</Box>
				{!notifications || notifications?.length === 0 ? (
					<Box sx={{ p: 2 }}>
						<Typography color="textPrimary" variant="subtitle2">
							There are no notifications
						</Typography>
					</Box>
				) : (
					<>
						<List disablePadding>
							{notifications?.map((notification) => {
								const Icon = iconsMap[notification?.icon] || User;

								return (
									<ListItem
										divider
										key={notification?.id}
										onClick={() => {
											navigate(notification?.link);
											handleMarkAsRead([notification?.notificationId]);
										}}
										style={{ cursor: "pointer" }}
										sx={!notification?.read && { backgroundColor: "background.default" }}>
										<ListItemAvatar>
											<Avatar
												sx={{
													backgroundColor: "primary.main",
													color: "primary.contrastText",
													borderRadius: 1,
												}}>
												<Icon fontSize="small" />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary={notification?.heading} secondary={notification?.text} />
									</ListItem>
								);
							})}
						</List>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								p: 1,
							}}>
							<Button
								color="primary"
								size="small"
								variant="text"
								onClick={() =>
									handleMarkAsRead(
										notifications.filter((n) => n?.read === false).map((n) => n?.notificationId)
									)
								}>
								Mark all as read
							</Button>
						</Box>
					</>
				)}
			</Popover>
		</>
	);
};

export default NotificationsPopover;
