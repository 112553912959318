import { gql } from "@apollo/client";

const GetClients = gql`
	query getClients {
		getClients {
			... on Client {
				clientId
				name
				status
				industry
				createdAt
				displayPic
				coverPic
				website
				memberCount
				exposure
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetClients;
