import { gql } from "@apollo/client";

const RemoveFromPlaylist = gql`
	mutation RemoveFromPlaylistMutation($payload: removeFromPlaylistInput!) {
		removeFromPlaylist(payload: $payload) {
			... on Playlist {
				playlistId
				name
			}
			... on Error {
				message
			}
		}
	}
`;

export default RemoveFromPlaylist;
