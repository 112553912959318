/* eslint-disable import/no-named-default */
import { useTheme } from "@emotion/react";
import { Autocomplete, Box, Chip, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import StandardButton from "components/StandardButton/StandardButton";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { default as ReactSelect } from "react-select/creatable";
import StarsRating from "stars-rating";
import QuillEditor from "./QuillEditor";

const NumberFormatCustom = React.forwardRef((props, ref) => {
	const { onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			prefix="$"
		/>
	);
});

// Use this for all form fields throughout app. Add additional 'types' if required
const FormField = ({
	type,
	name,
	label,
	placeholder,
	value,
	onChange,
	defaultValue,
	required,
	disabled,
	options,
	helperText,
	error,
	size,
	onCreateOption,
	multiHeight = 4,
	sx,
	loading,
	npsLabels = ["Not at all likely", "Extremely likely"],
}) => {
	const theme = useTheme();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				marginTop: 56,
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const customStyles = {
		option: (provided) => ({
			...provided,
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper,
			padding: "10px 20px",
		}),
		singleValue: (provided) => ({
			...provided,
		}),
		control: (provided) => ({
			...provided,
			padding: "5px 5px",
			borderRadius: "10px",
			borderColor: theme.palette.primary.main,
			borderWidth: 2,
			backgroundColor: theme.palette.background.paper,
		}),

		container: (provided) => ({
			zIndex: 999999,
			...provided,
		}),
	};

	// To keep track of quill editor value to update the hidden field. Also turns quill editor into controlled element
	const [quillEditorValue, setQuillEditorValue] = useState();

	const [selectValue, setSelectValue] = useState(defaultValue);
	const [starRating, setStarRating] = useState(defaultValue || 5);
	const [nps, setNps] = useState(defaultValue);

	useEffect(() => {
		// Set the default value for the quill editor
		if (defaultValue && type === "richText") {
			setQuillEditorValue(defaultValue);
		}
	}, [defaultValue]);

	if (type === "text" || type === "email" || type === "number") {
		return (
			<div>
				<TextField
					disabled={disabled}
					fullWidth
					label={label}
					name={name}
					required={required}
					value={value}
					onChange={onChange}
					variant="outlined"
					placeholder={placeholder}
					defaultValue={defaultValue}
					type={type}
					size={size}
					error={error}
					helperText={helperText}
					sx={sx}
				/>
			</div>
		);
	}
	if (type === "multiline") {
		return (
			<div>
				<TextField
					disabled={disabled}
					fullWidth
					label={label}
					name={name}
					required={required}
					value={value}
					rows={multiHeight}
					multiline
					onChange={onChange}
					variant="outlined"
					placeholder={placeholder}
					defaultValue={defaultValue}
					type={type}
					error={error}
					helperText={helperText}
					sx={sx}
				/>
			</div>
		);
	}
	if (type === "autocomplete") {
		return (
			<>
				<Autocomplete
					disabled={disabled}
					options={options}
					defaultValue={defaultValue}
					onChange={(_, val) => {
						setSelectValue(val);
						if (onChange) {
							onChange(val);
						}
					}}
					value={value}
					renderInput={(params) => (
						<TextField
							value={value}
							required={required}
							fullWidth
							label={label}
							variant="outlined"
							size={size}
							{...params}
							error={error}
							helperText={helperText}
							sx={sx}
						/>
					)}
				/>
				<input type="hidden" value={selectValue} name={name} required={required} />
			</>
		);
	}
	if (type === "select") {
		return (
			<>
				<Autocomplete
					disabled={disabled}
					getOptionLabel={(option) => options.find((o) => o.value === option)?.label || option.label}
					isOptionEqualToValue={(option, z) => option?.label === z}
					options={options.map((opt) => ({ ...opt, name: opt.name || opt.label }))}
					defaultValue={defaultValue}
					onChange={(event, val) => {
						setSelectValue(val?.value);
						if (onChange) {
							onChange(val);
						}
					}}
					value={value}
					renderInput={(params) => (
						<TextField
							value={value}
							required={required}
							fullWidth
							label={label}
							variant="outlined"
							size={size}
							{...params}
							error={error}
							helperText={helperText}
							sx={sx}
						/>
					)}
				/>
				<input type="hidden" value={selectValue} name={name} required={required} />
			</>
		);
	}
	if (type === "currency") {
		return (
			<TextField
				fullWidth
				disabled={disabled}
				required={required}
				defaultValue={defaultValue}
				sx={sx}
				label={label}
				value={value}
				onChange={onChange}
				name={name}
				// id={`${name}-currencyForm`}
				size={size}
				type="text"
				InputProps={{
					inputComponent: NumberFormatCustom,
				}}
				error={error}
				helperText={helperText}
			/>
		);
	}
	if (type === "password") {
		return (
			<div>
				<TextField
					fullWidth
					label={label}
					name={name}
					onChange={onChange}
					type="password"
					value={value}
					variant="outlined"
					size={size}
					required={required}
					error={error}
					helperText={helperText}
					sx={sx}
				/>
			</div>
		);
	}
	if (type === "multiselect") {
		return (
			<Select
				labelId={`${label}-select`}
				fullWidth
				multiple
				disabled={disabled}
				sx={sx}
				error={error}
				helperText={helperText}
				size={size}
				MenuProps={MenuProps}
				inputProps={{ sx: { p: 1.5 } }}
				getOptionSelected={(option, z) => option.value === z.value}
				renderValue={(selected) => (
					<>
						<Box sx={{ p: 0, m: 0 }}>
							{selected?.length > 0
								? selected.map((x, index) => (
										<Chip
											sx={{ ml: index === 0 ? 0 : 1 }}
											key={x}
											label={options?.find((loc) => loc.value === x)?.label}
										/>
								  ))
								: placeholder}
						</Box>
						{placeholder}
					</>
				)}
				contentEditable
				value={value}
				onChange={(event, v) => onChange && onChange(event, v)}>
				{options.map((item) => (
					<MenuItem key={item.label} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		);
	}
	if (type === "richText") {
		return (
			<>
				<Typography color="textPrimary" gutterBottom variant="subtitle2">
					{label && (
						<>
							{label}
							{required && "*"}:
						</>
					)}
				</Typography>
				<QuillEditor
					fullWidth
					disabled={disabled || false}
					required={required || false}
					label={label || ""}
					defaultValue={defaultValue || ""}
					value={quillEditorValue || ""}
					onChange={(event) => {
						setQuillEditorValue(event);
						if (onChange) {
							onChange(event);
						}
					}}
					placeholder={placeholder}
					sx={{ width: "100%" }}
				/>
				{/* This input is accessable for html uncontrolled forms. It is hidden and contains the output for quill editor */}
				<input type="hidden" value={quillEditorValue} name={name} />
			</>
		);
	}
	if (type === "toggle") {
		return (
			<>
				<Typography color="textPrimary" gutterBottom variant="subtitle2">
					{label}
				</Typography>
				<Typography color="textSecondary" variant="body2">
					{helperText}
				</Typography>
				<Switch
					disabled={disabled}
					color="primary"
					edge="start"
					name={name}
					onChange={(e) => onChange(e.target.checked)}
					checked={value}
				/>
			</>
		);
	}
	if (type === "tags") {
		return (
			<>
				<Typography color="textPrimary" gutterBottom variant="subtitle2">
					{label}
				</Typography>
				<ReactSelect
					options={options}
					isMulti
					isSearchable
					allowCreateWhileLoading
					onCreateOption={(e) => onCreateOption(e)}
					onChange={(e) => onChange(e)}
					value={value}
					placeholder={placeholder}
					isLoading={loading}
					styles={customStyles}
				/>
			</>
		);
	}

	if (type === "stars") {
		return (
			<>
				<Typography color="textPrimary" variant="subtitle2">
					{label}
				</Typography>
				<StarsRating
					count={5}
					value={starRating}
					onChange={(val) => setStarRating(val)}
					size={36}
					color2="#ffd700"
				/>
				<input type="hidden" value={starRating} name={name} />
			</>
		);
	}

	if (type === "nps") {
		return (
			<>
				<Typography color="textPrimary" variant="subtitle2">
					{label}
				</Typography>
				<Box display="inline-block">
					{Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
						<StandardButton
							text={i}
							buttonStyle="primary"
							key={`ratingscalebutton-${i}`}
							onClick={() => {
								if (nps) {
									setNps(null);
								} else setNps(i);
							}}
							disabled={nps && nps !== i}
						/>
					))}
					<Box display="flex" justifyContent="space-between">
						<Typography color="textSecondary" variant="subtitle2">
							{npsLabels[0]}
						</Typography>
						<Typography color="textSecondary" variant="subtitle2">
							{npsLabels[1]}
						</Typography>
					</Box>
				</Box>

				<input type="hidden" value={nps} name={name} />
			</>
		);
	}

	return null;
};

export default FormField;
