// INPUTS:
// "capFirst" -- CAPITALISE FIRST LETTER IN EVERY WORD
// "keywords" -- TAKES COMMA SEPARATED KEYWORDS STRING AND CONVERTS TO KEYWORDS ARRAY
// "catString" -- TAKES KEYWORDS ARRAY AND CONVERTS TO COMMA SEPARATED STRING
const capitaliseFirst = (str) => {
	const words = str.split(" ");
	let result = "";
	words.forEach((w) => {
		const i = w.toLowerCase();
		const res = i.charAt(0).toUpperCase() + i.slice(1);
		if (result.length > 0) {
			result = `${result} ${res}`;
		} else {
			result = res;
		}
	});
	return result;
};

const keywordArrayGen = (str) => {
	const x = str.split(",");
	const res = [];
	x.forEach((word) => {
		if (word[0] === " ") {
			const f = word.substring(1);
			res.push(f.toUpperCase());
		} else {
			res.push(word.toUpperCase());
		}
	});
	return res;
};

const keywordArrayFormat = (arr) => {
	if (arr.length === 0) return null;
	let res = "";
	arr.forEach((i, index) => {
		if (index === 0) {
			res = capitaliseFirst(i);
		} else {
			res = `${res}, ${capitaliseFirst(i)}`;
		}
	});
	return res;
};

const categoryStringHelper = (str, type = "capFirst", arr = []) => {
	if (typeof str !== "string" && !str.length) return "";
	switch (type) {
		case "capFirst":
			return capitaliseFirst(str);
		case "keywords":
			return keywordArrayGen(str);
		case "catString":
			return keywordArrayFormat(arr);
		default:
			return str;
	}
};

export default categoryStringHelper;
