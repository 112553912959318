/* eslint-disable no-undef */
import { Box, Divider, Drawer, Typography } from "@mui/material";
import Hidden from "components/common/Hidden";
import Logo from "components/common/Logo";
import NavSection from "components/common/NavSection";
import Scrollbar from "components/common/Layout/Scrollbar";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import StandardButton from "components/StandardButton/StandardButton";

const SideBar = ({ onMobileClose, openMobile, menuArr, topBox }) => {
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	// Menu content, not directly rendered
	const content = (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}>
			<Scrollbar options={{ suppressScrollX: true }} style={{ height: "100%" }}>
				<Hidden rules="lgUp">
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							px: 2,
							pt: 2,
						}}>
						<RouterLink to="/">
							<Logo
								type="login"
								sx={{
									height: "auto",
									width: 180,
								}}
							/>
						</RouterLink>
					</Box>
				</Hidden>
				{topBox}
				<Box sx={{ p: 2 }}>
					{menuArr?.map((section) => (
						<NavSection
							key={section.title}
							pathname={location.pathname}
							sx={{
								"& + &": {
									mt: 3,
								},
							}}
							{...section}
						/>
					))}
				</Box>
			</Scrollbar>
			<Divider />
			<Box sx={{ p: 2 }}>
				<Typography color="textPrimary" variant="subtitle2">
					Need Help?
				</Typography>
				<Typography sx={{ mb: 1 }} color="textSecondary" variant="body2">
					We are here to answer any questions you may have.
				</Typography>
				<StandardButton
					buttonStyle="secondary"
					sx={{ width: "100%", ml: 0 }}
					text="Contact us"
					onClick={() => window.open("https://support.careerbase.co?utm_source=appcta")}
				/>
			</Box>
		</Box>
	);

	// Render mobile and desktop versions
	return (
		<>
			<Hidden rules="lgUp">
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					PaperProps={{
						sx: {
							backgroundColor: "background.paper",
							width: 280,
						},
					}}
					variant="temporary">
					{content}
				</Drawer>
			</Hidden>
			<Hidden rules="lgDown">
				<Drawer
					anchor="left"
					open
					PaperProps={{
						sx: {
							backgroundColor: "background.paper",
							height: "calc(100% - 64px) !important",
							top: "64px !Important",
							width: 280,
						},
					}}
					variant="persistent">
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

export default SideBar;
