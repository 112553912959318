import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import CreateFeedback from "apollo/mutations/CreateFeedback";
import GetEngagementUnsecured from "apollo/queries/GetEngagementUnsecured";
import FormField from "components/FormField/FormField";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import PageContainer from "components/PageContainer/PageContainer";
import PageHeader from "components/PageHeader/PageHeader";
import StandardButton from "components/StandardButton/StandardButton";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import providerTypes from "utils/providerTypes";
import showToast from "utils/showToast";

// eslint-disable-next-line arrow-body-style
const Feedback = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { eid } = useParams();

	const { data } = useQuery(GetEngagementUnsecured, {
		variables: { payload: { engagementId: eid } },
	});

	const [createFeedback] = useMutation(CreateFeedback);

	const engagementDetails = data?.getEngagementUnsecured || {};

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.persist();
		try {
			setLoading(true);
			const payload = {
				variables: {
					payload: {
						engagementId: eid,
						questions: {
							nps: e.target.nps.value,
							stars: e.target.stars.value,
							engagement: e.target.engagement.value,
							wellness: e.target.wellness.value,
						},
					},
				},
			};

			const create = await createFeedback(payload);

			if (create.data.createFeedback.__typename === "Error") throw new Error(create.data.createFeedback.message);

			showToast("Feedback successfully submitted!", "success");
			navigate("/");
			setLoading(false);
		} catch (err) {
			setLoading(false);
			showToast(err.message, "error");
		}
	};

	const type = engagementDetails?.type || "COACH";

	const correctType = providerTypes.find((t) => t.value === type).label;

	if (!data) {
		return <LoadingScreen />;
	}

	if (engagementDetails.feedbackSubmitted) {
		return (
			<PageContainer>
				<Typography color="textPrimary" variant="subtitle2" sx={{ mt: 5 }}>
					Feedback for this offering has already been submitted.
				</Typography>
				<StandardButton text="Go Home" onClick={() => navigate("/")} />
			</PageContainer>
		);
	}

	return (
		<>
			<PageHeader title="Feedback" />

			<PageContainer>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12} sx={{ mb: 3 }}>
							<FormField
								label={`How would you rate the quality of the ${correctType.toLowerCase()}?`}
								type="stars"
								name="stars"
							/>
						</Grid>
						<Grid item md={12} xs={12} sx={{ mb: 3 }}>
							<FormField
								label={`Would you recommend the ${correctType.toLowerCase()} to others with similar goals or needs?`}
								type="nps"
								name="nps"
							/>
						</Grid>
						<Grid item md={12} xs={12} sx={{ mb: 3 }}>
							<FormField
								label={`To what extent has this ${correctType.toLowerCase()} improved your effectiveness at work?`}
								type="nps"
								name="nps"
								npsLabels={["Not at all", "A great deal"]}
							/>
						</Grid>
						{/* <Grid item md={12} xs={12} sx={{ mb: 1 }}>
							<Typography color="textPrimary" variant="subtitle2">
								What impact did the {correctType} have on your:{" "}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12} sx={{ mb: 2 }}>
							<FormField label="Engagement?" type="stars" name="engagement" />
						</Grid>
						<Grid item md={12} xs={12} sx={{ mb: 2 }}>
							<FormField label="Productivity?" type="stars" name="productivity" />
						</Grid>
						<Grid item md={12} xs={12} sx={{ mb: 2 }}>
							<FormField label="Wellness?" type="stars" name="wellness" />
						</Grid> */}
					</Grid>
					<Grid>
						<FormField label="Any additional comments" type="multiline" />
					</Grid>
					<Grid item md={12} xs={12} sx={{ mb: 3 }}>
						<Box display="flex" flexDirection="row-reverse" sx={{ width: "100%" }}>
							<StandardButton type="submit" text="Submit" loading={loading} />
						</Box>
					</Grid>
				</form>
			</PageContainer>
		</>
	);
};

export default Feedback;
