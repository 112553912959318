import { gql } from "@apollo/client";

const GetImageInstance = gql`
	query GetImageInstance($payload: getImageInstanceInput!) {
		getImageInstance(payload: $payload) {
			... on ImageInstance {
				imageInstanceId
				imageResource {
					imageResourceId
					downloadUrl
				}
				downloadUrl
				zoom
				cropX
				cropY
				createdAt
				updatedAt
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetImageInstance;
