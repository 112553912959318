import { useMutation } from "@apollo/client";
import amplitude from "config/amplitude";
import showToast from "utils/showToast";
import CreateAnalyticEvent from "../apollo/mutations/CreateAnalyticEvent";
import useAuth from "./useAuth";

const useAnalytics = () => {
	const [createAnalyticEvent] = useMutation(CreateAnalyticEvent);
	const { activeUser } = useAuth();

	const logEvent = async ({ eventName, properties }) => {
		try {
			if (!eventName) throw new Error("Event name is required");

			await amplitude.getInstance().logEvent(eventName, { ...properties, clientId: activeUser?.clientId });
			const payload = {
				variables: {
					payload: {
						userId: parseFloat(activeUser.id),
						clientId: activeUser?.clientId,
						properties: properties || {},
						name: eventName,
					},
				},
			};
			await createAnalyticEvent(payload);
		} catch (err) {
			showToast(err.message, "error");
		}
	};

	const identifyUser = (uid) => {
		amplitude.getInstance().setUserId(uid);
	};

	return { logEvent, identifyUser };
};

export default useAnalytics;
