import { useMutation } from "@apollo/client";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { styled } from "@mui/styles";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import UpdateNotificationStatus from "apollo/mutations/UpdateNotificationStatus";
import Hidden from "components/common/Hidden";
import Logo from "components/common/Logo";
import NotificationsPopover from "components/NotificationsPopover/NotificationsPopover";
import SettingsContext from "contexts/SettingsContext";
import useAuth from "hooks/useAuth";
import MenuIcon from "icons/Menu";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import showToast from "utils/showToast";
import AccountPopover from "./AccountPopover";
import NavLinks from "./NavLinks";
import ProgramSelector from "./ProgramSelector";

const AdminNavbarRoot = styled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === "light" && {
		backgroundColor: theme.palette.primary.main,
		boxShadow: "none",
		color: theme.palette.primary.contrastText,
	}),
	...(theme.palette.mode === "dark" && {
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: "none",
	}),
	zIndex: theme.zIndex.drawer + 100,
}));

const Navbar = ({ onSidebarMobileOpen, type }) => {
	const { activeUser, refetchUser } = useAuth();
	const { saveSettings, settings } = useContext(SettingsContext);
	const [updateNotificationStatus] = useMutation(UpdateNotificationStatus);
	const toggleTheme = () => {
		if (settings.theme === "LIGHT") {
			saveSettings({ ...settings, theme: "DARK" });
		} else {
			saveSettings({ ...settings, theme: "LIGHT" });
		}
	};

	const handleMarkAsRead = async (notificationIdArr) => {
		try {
			const payload = {
				variables: {
					payload: {
						notificationId: notificationIdArr,
						status: "OPENED",
						uid: activeUser.uid,
					},
				},
			};

			await updateNotificationStatus(payload);

			await refetchUser();
		} catch (err) {
			showToast("Something went wrong. Please try again.", "error");
		}
	};

	return (
		<AppBar>
			<Toolbar sx={{ minHeight: 64 }}>
				<Hidden rules="lgUp">
					<IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
						<MenuIcon fontSize="small" />
					</IconButton>
				</Hidden>
				<Hidden rules="mdDown">
					<RouterLink to={`/${type}`}>
						<Logo
							sx={{
								height: 41.8,
								width: 188,
							}}
						/>
					</RouterLink>
					<NavLinks type={type} />
				</Hidden>
				<Box
					sx={{
						flexGrow: 1,
						ml: 2,
					}}
				/>
				{/* I've commented out content search for now, to be released in later version */}
				{/* <Box sx={{ ml: 1 }}>
					<ContentSearch />
				</Box> */}
				<Box sx={{ ml: 1 }}>
					<ProgramSelector />
				</Box>
				<Box sx={{ ml: 1, pt: 1 }}>
					<NotificationsPopover
						handleMarkAsRead={handleMarkAsRead}
						notifications={activeUser?.notifications || []}
					/>
				</Box>
				<Box sx={{ ml: 1, pt: 1.4 }}>
					<InvertColorsIcon onClick={toggleTheme} />
				</Box>
				<Box sx={{ ml: 2 }}>
					<AccountPopover type={type} />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
