import { useQuery } from "@apollo/client";
import { Card, Typography } from "@mui/material";
import PageContainer from "components/PageContainer/PageContainer";
import PageHeader from "components/PageHeader/PageHeader";
import AuthContext from "contexts/AuthContext";
import SearchResultsPage from "components/SearchResults@dep/SearchResults";
import React, { useContext, useEffect } from "react";
import useAnalytics from "hooks/useAnalytics";
import GetFavorites from "apollo/queries/GetFavorites";

const FavoritesPage = () => {
	const { activeUser } = useContext(AuthContext);
	const { logEvent } = useAnalytics();

	const { data, refetch } = useQuery(GetFavorites, {
		notifyOnNetworkStatusChange: true,
		variables: { payload: { userId: parseInt(activeUser?.id, 10) } },
		skip: !activeUser?.uid,
	});

	useEffect(() => {
		if (activeUser?.id) {
			// Log event to analytics
			logEvent({
				eventName: "view_favorites",
			});
		}
	}, [activeUser]);

	const favArr = data?.getFavorites?.map((p) => ({ ...p, isFavourite: true })) || [];

	return (
		<>
			<PageHeader maxWidth="lg" title="My Favorites" />
			<PageContainer maxWidth="lg">
				<Card sx={{ p: 3, mb: 0 }}>
					<Typography sx={{ mb: 2 }} color="textPrimary" variant="subtitle2">
						Below you can find a your selection of shortlisted favorite offerings.
					</Typography>
					<Typography sx={{ mb: 2 }} color="textPrimary" variant="subtitle2">
						Browse the selection and purchase individually.
					</Typography>
				</Card>
			</PageContainer>
			<SearchResultsPage offerings={favArr} refetch={refetch} />
			<br />
			<br />
		</>
	);
};

export default FavoritesPage;
