import { gql } from "@apollo/client";

const CreatePlaylist = gql`
	mutation Mutation($payload: createPlaylistInput) {
		createPlaylist(payload: $payload) {
			... on Playlist {
				clientId
				name
				description
				playlistId
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreatePlaylist;
