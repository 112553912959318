import { graphqlEndpoint } from "../../apollo/apolloClientProvider";
import { checkSSOString } from "../../apollo/mutations/CheckSSO";

const authGetSSOUser = async (payload, token) => {
	try {
		// eslint-disable-next-line no-undef
		const request = await fetch(graphqlEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: token,
			},
			body: JSON.stringify({
				query: checkSSOString,
				variables: {
					payload,
				},
			}),
		}).then((res) => res.json());

		return request.data.checkSSO;
	} catch (error) {
		console.log(error);
		throw new Error("Could not get users details");
	}
};

export default authGetSSOUser;
