import { gql } from "@apollo/client";

const CreateImageResource = gql`
	mutation CreateImageResource($payload: createImageResourceInput!) {
		createImageResource(payload: $payload) {
			... on ImageResource {
				imageResourceId
				imageType
				createdAt
				downloadUrl
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateImageResource;
