import { Container, Grid } from "@mui/material";
import React from "react";

const PageContainer = ({ maxWidth = "xl", children }) => (
	<Container maxWidth={maxWidth}>
		<Grid container spacing={3}>
			<Grid xs={12} item>
				{children}
			</Grid>
		</Grid>
	</Container>
);

export default PageContainer;
