import { gql } from "@apollo/client";

export const getUserString = `query getUser($payload: getUserInput!) {
		getUser(payload: $payload) {
			...on User {
			id
			uid
			firstName
			lastName
			email
			type
			clientId
			favourites
			providerId
			status
			createdAt
			lastSeen
			displayPic
			coverPic
			location
			mobile
			title
			notifications { 
      heading
      text
      link
      icon
      read
      notificationId
    }
	teamsManaging {
        teamId
        name
        description
      }
			clientAccount {
				displayPic
				name
				clientId
				subType
				balance
				programs {
					programId
					name
				}
			}
			programs {
				programId
				playlistId
				searchKey
				name
				status
				clientId
				deleted
			}
			engagements {
				engagementId
				status
				program {
					programId
					name
				}
				offering {
					offeringId
					name
				}
				approvalData
				ProgramTransaction {
					offeringId
					offeringType
					amount
				}
			}
			programAccounts {
				programId
				programAccountId
				balance
				program {
					name
					programId
					playlistId
					
				}
			}
		
		}
		...on Error {
		message
	}
	}
	

	}`;

const GetUser = gql`
	${getUserString}
`;
export default GetUser;
