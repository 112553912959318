import { gql } from "@apollo/client";

const AddToPlaylist = gql`
	mutation AddToPlaylistMutation($payload: addToPlaylistInput!) {
		addToPlaylist(payload: $payload) {
			... on Playlist {
				playlistId
				name
			}
			... on Error {
				message
			}
		}
	}
`;

export default AddToPlaylist;
