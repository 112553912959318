import { gql } from "@apollo/client";

const CreateFeedback = gql`
	mutation CreateFeedback($payload: createFeedbackInput) {
		createFeedback(payload: $payload) {
			... on Feedback {
				feedbackId
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateFeedback;
