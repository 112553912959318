/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, Chip, CircularProgress, Fab, Link, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";

// This is the standard button which must be used throughout app
const StandardButton = ({ text, onClick, buttonStyle, icon, disabled, loading, type, sx, size = "medium" }) => {
	// THREE BUTTON STYLES
	// primary
	// secondary
	// tertiary
	// tertiary purple
	// back

	// If it's a back button
	if (buttonStyle === "back") {
		return (
			<Fab onClick={onClick} size="small" sx={{ zIndex: 8, cursor: "pointer", ...sx }}>
				{loading ? <CircularProgress size={20} color="inherit" /> : <ArrowBack />}
			</Fab>
		);
	}

	if (buttonStyle === "text") {
		return (
			<Link>
				<Box onClick={onClick} display="flex" flexDirection="row">
					<Typography sx={{ mr: icon ? 1 : 0, cursor: "pointer", ...sx }}>{text}</Typography>
					{icon}
				</Box>
			</Link>
		);
	}

	if (buttonStyle === "primaryChip") {
		return (
			<Chip
				disabled={disabled}
				size={size}
				label={text}
				icon={loading ? <CircularProgress size={18} color="inherit" /> : icon}
				color="secondary"
				onClick={onClick}
				sx={{ cursor: "pointer", borderRadius: 2, ...sx }}
			/>
		);
	}

	if (buttonStyle === "secondaryChip") {
		return (
			<Chip
				disabled={disabled}
				size={size}
				label={text}
				icon={loading ? <CircularProgress size={18} color="inherit" /> : icon}
				color="primary"
				variant="outlined"
				onClick={onClick}
				sx={{ cursor: "pointer", borderRadius: 2, ...sx }}
			/>
		);
	}

	// Decide on button styles
	let buttonStyleString = "secondary";
	let variantStyleString = "contained";
	if (buttonStyle === "secondary") {
		variantStyleString = "outlined";
	} else if (buttonStyle === "tertiary") {
		buttonStyleString = "inherit";
		variantStyleString = "text";
	} else if (buttonStyle === "tertiary purple") {
		buttonStyleString = "primary";
		variantStyleString = "text";
	} else if (buttonStyle === "decline") {
		buttonStyleString = "error";
	} else if (buttonStyle === "approve") {
		buttonStyleString = "success";
	}

	// Otherwise, its your regular button
	return (
		<Button
			size={size}
			disabled={disabled || loading}
			onClick={onClick}
			type={type || "button"}
			color={buttonStyleString}
			endIcon={loading ? <CircularProgress size={20} color="inherit" /> : icon}
			sx={{ m: 1, ...sx }}
			variant={variantStyleString}>
			{text}
		</Button>
	);
};

export default StandardButton;
