import { gql } from "@apollo/client";

const AddReccomendation = gql`
	mutation AddReccomendation($payload: addReccomendationInput) {
		addReccomendation(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default AddReccomendation;
