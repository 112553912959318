const { toast } = require("react-toastify");

const showToast = (message, type) => {
	toast[type || "success"](message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	});
};

export default showToast;
