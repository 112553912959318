/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from "@apollo/client";
import {
	Avatar,
	Box,
	Card,
	CardMedia,
	Chip,
	Grid,
	Link,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography,
} from "@mui/material";
import { Favorite, FavoriteBorder, Label, List, RemoveCircle } from "@mui/icons-material";
import AddFavorite from "apollo/mutations/AddFavorite";
import AddReccomendation from "apollo/mutations/AddReccomendation";
import DeleteFavorite from "apollo/mutations/DeleteFavorite";
import DeleteReccomendation from "apollo/mutations/DeleteReccomendation";
import StandardButton from "components/StandardButton/StandardButton";
import useAnalytics from "hooks/useAnalytics";
import useAuth from "hooks/useAuth";
import DotsVertical from "icons/DotsVertical";
import Plus from "icons/Plus";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import categoryStringHelper from "utils/capitalisationHelper";
import deliveryTypes from "utils/deliveryTypes";
import formatDate from "utils/formatDate";
import formatNumber from "utils/formatNumber";
import getRandomCover from "utils/getRandomCover";
import showToast from "utils/showToast";
import PlaylistEditModal from "../PlaylistModal";

const SearchResultNEW = ({ data, refetch }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [playlistModal, setPlaylistModal] = useState(false);

	if (!data) {
		return null;
	}
	const navigate = useNavigate();
	const location = useLocation();
	const { activeUser, activeProgram, cachedUser } = useAuth();

	const [addReccomendation] = useMutation(AddReccomendation);
	const [deleteRecommendation] = useMutation(DeleteReccomendation);

	const [isRecommended, setIsRecommended] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);

	const [addFavorite] = useMutation(AddFavorite);
	const [deleteFavorite] = useMutation(DeleteFavorite);

	const { logEvent } = useAnalytics();

	useEffect(() => {
		if (data?.isRecommended) {
			setIsRecommended(true);
		}

		if (data?.isFavourite) {
			setIsFavorite(true);
		}
	}, [data]);

	const baseLink = `/${location.pathname.split("/")[1]}/marketplace/`;

	let linkText = "View Offering";
	if (data.type === "SERVICE") {
		linkText = "View Service";
	} else if (data.type === "COURSE") {
		linkText = "View Course";
	} else if (data.type === "COACH") {
		linkText = "View Package";
	} else if (data.type === "SUBSCRIPTION") {
		linkText = "View Subscription";
	} else if (data.type === "EVENT") {
		linkText = "View Event";
	}

	const link = `${baseLink}offering/${data.offeringId}`;

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const addToReccomended = async (offeringId) => {
		try {
			const payload = {
				variables: {
					payload: {
						programId: activeProgram?.programId,
						offeringId,
						userId: parseFloat(activeUser?.id),
					},
				},
			};
			await addReccomendation(payload);

			setIsRecommended(true);

			showToast("Offering added to reccomendations", "success");
		} catch (err) {
			showToast(err.message, "error");
		}
	};

	const deleteReccomend = async (offeringId) => {
		try {
			const payload = {
				variables: {
					payload: {
						programId: activeProgram?.programId,
						offeringId,
						userId: parseFloat(activeUser?.id),
					},
				},
			};
			await deleteRecommendation(payload);

			setIsRecommended(false);

			if (refetch) {
				refetch();
			}

			showToast("Offering removed from reccomendations", "success");
		} catch (err) {
			showToast(err.message, "error");
		}
	};

	const handleFavorite = async (offeringId, makeFavorite) => {
		try {
			const payload = {
				variables: {
					payload: {
						offeringId,
						userId: parseFloat(activeUser?.id),
					},
				},
			};

			setIsFavorite(makeFavorite);

			if (makeFavorite) {
				await addFavorite(payload);
				logEvent({ eventName: "add_favorite", properties: { offeringId } });

				showToast("Offering added to favorites");
			} else {
				await deleteFavorite(payload);
				logEvent({ eventName: "delete_favorite", properties: { offeringId } });

				showToast("Offering removed from favorites");
			}

			if (refetch) {
				refetch();
			}
		} catch (err) {
			showToast(err.message, "error");
			setIsFavorite(!makeFavorite);
		}
	};

	const shouldShow = (type) => {
		switch (type) {
			case "recos": {
				if (activeUser.type === "MEMBER" && cachedUser.type === "ADMIN") {
					return true;
				}
				return false;
			}
			case "playlist": {
				if (activeUser.type === "MANAGER" || activeUser.type === "ADMIN") {
					return true;
				}
				return false;
			}
			default: {
				return false;
			}
		}
	};

	const togglePlaylistModal = () => {
		handleClose();
		setPlaylistModal(!playlistModal);
	};

	const showPopover = shouldShow("playlist") || shouldShow("recos");

	return (
		<Card sx={{ width: 360, height: 452 }}>
			<PlaylistEditModal
				open={playlistModal}
				onClose={togglePlaylistModal}
				offeringId={data.offeringId}
				offering={data}
			/>
			<Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
				<Box>
					<Box sx={{ p: 0 }}>
						<div style={{ position: "relative", textAlign: "center", color: "white" }}>
							<CardMedia
								image={data?.coverPic || getRandomCover()}
								sx={{
									backgroundColor: "background.default",
									height: 100,
								}}
							/>

							<>
								{showPopover && (
									<DotsVertical
										style={{ position: "absolute", top: "8px", right: "6px", cursor: "pointer" }}
										onClick={(event) => {
											setAnchorEl(event.currentTarget);
										}}
									/>
								)}
							</>

							{isFavorite ? (
								<Favorite
									style={{
										position: "absolute",
										top: "8px",
										right: showPopover ? "36px" : "8px",
										cursor: "pointer",
									}}
									onClick={() => handleFavorite(data.offeringId, false)}
								/>
							) : (
								<FavoriteBorder
									style={{
										position: "absolute",
										top: "8px",
										right: showPopover ? "36px" : "8px",
										cursor: "pointer",
									}}
									onClick={() => handleFavorite(data.offeringId, true)}
								/>
							)}

							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									horizontal: "left",
									vertical: "bottom",
								}}>
								{shouldShow("recos") && (
									<>
										{!isRecommended ? (
											<MenuItem onClick={() => addToReccomended(data.offeringId)}>
												<ListItemIcon>
													<Plus fontSize="small" />
												</ListItemIcon>
												<ListItemText
													primary={
														<Typography color="textPrimary" variant="subtitle2">
															Add To Reccomendations
														</Typography>
													}
												/>
											</MenuItem>
										) : (
											<MenuItem onClick={() => deleteReccomend(data.offeringId)}>
												<ListItemIcon>
													<RemoveCircle fontSize="small" />
												</ListItemIcon>
												<ListItemText
													primary={
														<Typography color="textPrimary" variant="subtitle2">
															Remove From Reccomendations
														</Typography>
													}
												/>
											</MenuItem>
										)}
									</>
								)}
								{shouldShow("playlist") && (
									<MenuItem onClick={togglePlaylistModal}>
										<ListItemIcon>
											<List fontSize="small" />
										</ListItemIcon>
										<ListItemText
											primary={
												<Typography color="textPrimary" variant="subtitle2">
													Manage Playlists
												</Typography>
											}
										/>
									</MenuItem>
								)}
							</Popover>

							<Avatar
								sx={{ borderRadius: 1, height: 90, width: 90, position: "absolute", top: 56, ml: 2 }}
								src={data?.provider?.displayPic}
							/>
						</div>
						<Box display="flex" flexDirection="row-reverse" sx={{ width: "100%", px: 2, pt: 1 }}>
							<Typography sx={{ textAlign: "right" }} variant="subtitle2" color="textSecondary">
								{/* Hide sale price for this provider's offerings */}
								{data?.provider?.providerId !== activeUser?.providerId ? (
									<>
										{activeUser.type === "MEMBER"
											? formatNumber(data?.salePrice, "points")
											: formatNumber(data?.salePrice, "currency")}
									</>
								) : (
									"-"
								)}
							</Typography>
						</Box>
						<Box
							sx={{
								pt: 3,
								px: 2,
								pb: 1,
							}}>
							<Box>
								<Link
									onClick={() => navigate(link)}
									color="textPrimary"
									sx={{
										lineHeight: 1.3,
										cursor: "pointer",
										maxLines: 2,
										display: "block",
										WebkitLineClamp: 2,
										overflow: "hidden",
										textOverflow: "ellipsis",
										lineClamp: 2,
										maxHeight: 44,
										wordWrap: "break-word",
									}}
									variant="h6">
									{data?.name}
								</Link>
							</Box>
							<Link
								onClick={() => navigate(`${baseLink}search/?providerId=${data?.provider?.providerId}`)}
								color="textSecondary">
								<Typography
									color="textSecondary"
									sx={{ cursor: "pointer", pt: 0.5 }}
									variant="subtitle2">
									{data?.provider?.name}
								</Typography>
							</Link>
						</Box>
					</Box>
					<Box
						sx={{
							px: 2,
							pt: 1,
						}}>
						{data.categories && (
							<Box sx={{ maxHeight: 58, overflow: "hidden" }}>
								{data.categories.map((cat) => (
									<Chip
										label={categoryStringHelper(cat.value)}
										size="small"
										sx={{ mb: 1, mr: 1, fontSize: 12 }}
									/>
								))}
							</Box>
						)}
						<Grid sx={{ pt: 3 }} alignItems="center" container justifyContent="space-between" spacing={3}>
							<Grid xs={6} item>
								<Typography color="textPrimary" variant="subtitle2">
									{deliveryTypes.find((d) => d.value === data?.delivery)?.label}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									Delivery
								</Typography>
							</Grid>
							{data?.nextDate && (
								<Grid xs={6} item>
									<Typography color="textPrimary" variant="subtitle2">
										{formatDate(data?.nextDate)}
									</Typography>
									<Typography color="textSecondary" variant="body2">
										When
									</Typography>
								</Grid>
							)}
							{/* <Grid xs={6} item>
								<Typography color="textPrimary" variant="subtitle2">
									{data?.location ||
										locationsList.find((t) => t.value === data?.provider?.location)?.label ||
										""}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									Location
								</Typography>
							</Grid> */}
						</Grid>
					</Box>
				</Box>
				<Box>
					<Box
						flexDirection="row-reverse"
						sx={{
							alignItems: "center",
							justifyContent: "space-between",
							display: "flex",
							pl: 1,
							pr: 1,
							pt: 2,
						}}>
						{data.purchased !== undefined && (
							<Label color={data.purchased ? "secondary" : "primary"}>
								{data.purchased ? "Purchased" : "Not Purchased"}
							</Label>
						)}
						<StandardButton
							sx={{ mr: 0 }}
							buttonStyle="tertiary purple"
							text={linkText}
							onClick={() => navigate(link)}
						/>
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

export default SearchResultNEW;
