import { gql } from "@apollo/client";

const AddFavorite = gql`
	mutation AddFavorite($payload: addFavoriteInput) {
		addFavorite(payload: $payload) {
			... on SuccessString {
				message
			}
			... on Error {
				message
			}
		}
	}
`;

export default AddFavorite;
