/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTheme } from "@emotion/react";
import { Box, Button, Container, Link, Typography, useMediaQuery } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import DisplayPicEditable from "components/common/DisplayPicEditable";
import Hidden from "components/common/Hidden";
import ImageUploadModal from "components/common/ImageUpload/ImageUploadModal";
import StandardButton from "components/StandardButton/StandardButton";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import getRandomCover from "utils/getRandomCover";

// The profile header with profile pic and cover image, like linkedin.
const ImageHeader = ({
	refetch,
	buttons,
	title,
	subtitle,
	backLink,
	coverPic,
	displayPic,
	subtitleUrl = false,
	disableDisplayPic = false,
	disableCoverPic = false,
	ownerId,
	ownerType,
	hideDisplayPic,
	maxWidth = "lg",
}) => {
	const [dpModal, setDpModal] = useState(false);
	const [cpModal, setCpModal] = useState(false);

	const navigate = useNavigate();

	const toggleDpModal = () => setDpModal(!dpModal);

	const toggleCpModal = () => setCpModal(!cpModal);

	const theme = useTheme();

	const mobile = useMediaQuery(theme.breakpoints.up("sm"));

	const goBack = () => {
		if (backLink) {
			navigate(backLink);
		} else {
			navigate(-1);
		}
	};

	return (
		<>
			{/* Modals */}
			<ImageUploadModal
				image={coverPic}
				open={cpModal}
				onClose={toggleCpModal}
				refetch={refetch}
				imageType="coverPic"
				ownerId={ownerId}
				ownerType={ownerType}
			/>
			<Box
				sx={{
					backgroundColor: "background.default",
					minHeight: "306px",
				}}>
				<Box
					style={{ backgroundImage: `url(${coverPic || getRandomCover()})` }}
					sx={{
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "100%",
						height: !mobile ? 170 : 230,
						position: "relative",
						"&:before": {
							backgroundImage: "linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)",
							content: '" "',
							height: "100%",
							left: 0,
							position: "absolute",
							top: 0,
							width: "100%",
						},
						"&:hover": {
							"& button": {
								visibility: "visible",
							},
						},
					}}>
					<Container maxWidth={maxWidth} sx={{ pt: 2 }}>
						<StandardButton buttonStyle="back" onClick={() => goBack()} />
					</Container>

					{!disableCoverPic && (
						<Button
							startIcon={<AddPhotoIcon fontSize="small" />}
							onClick={toggleCpModal}
							sx={{
								backgroundColor: blueGrey[900],
								bottom: {
									lg: 24,
									xs: "auto",
								},
								color: "common.white",
								position: "absolute",
								right: 24,
								top: {
									lg: "auto",
									xs: 24,
								},
								visibility: "hidden",
								"&:hover": {
									backgroundColor: blueGrey[900],
								},
							}}
							variant="contained">
							Change Cover
						</Button>
					)}
				</Box>
				<Container maxWidth={maxWidth}>
					<Box
						sx={{
							display: "flex",
							mt: 1,
							position: "relative",
						}}>
						{!hideDisplayPic && (
							<DisplayPicEditable
								image={displayPic}
								open={dpModal}
								toggle={toggleDpModal}
								refetch={refetch}
								disabled={disableDisplayPic}
								ownerId={ownerId}
								ownerType={ownerType}
							/>
						)}
						<Box
							sx={{
								ml: hideDisplayPic ? "0px" : "160px",
								pt: 0.5,
								"@media (max-width:800px)": { ml: "110px" },
							}}>
							<Typography sx={{ maxWidth: 650 }} color="textPrimary" variant="h5">
								{title}
							</Typography>
							{subtitleUrl ? (
								<Link onClick={() => navigate(subtitleUrl)} color="textSecondary">
									<Typography color="textSecondary" sx={{ cursor: "pointer" }} variant="subtitle2">
										{subtitle}
									</Typography>
								</Link>
							) : (
								<Typography color="textSecondary" variant="subtitle2">
									{subtitle}
								</Typography>
							)}
						</Box>
						<Box sx={{ flexGrow: 1 }} />
						<Hidden rules="smDown">
							<Box sx={{ pt: 1 }}>{buttons}</Box>
						</Hidden>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default ImageHeader;
