import useAuth from "hooks/useAuth";
import Login from "pages/authentication/login/Login";
import { useLocation, useNavigate } from "react-router";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const AuthGuard = ({ children }) => {
	const { activeUser, cachedUser } = useAuth();

	const location = useLocation();
	const navigate = useNavigate();

	const arr = [
		{ url: "client", type: "MANAGER" },
		{ url: "clientv2", type: "PRESCRIBER" },
		{ url: "admin", type: "ADMIN" },
		{ url: "member", type: "MEMBER" },
		{ url: "provider", type: "PROVIDER" },
	];

	if (activeUser === null) {
		return <LoadingScreen />;
	}

	const urlType = location.pathname.split("/")[1];

	const userTypeObj = arr.find((i) => i.type === activeUser?.type) || {};
	const isImpersonating = userTypeObj.url !== urlType;

	const redirectToHome = () => {
		console.log("Heres the problems");
		navigate(`/${userTypeObj?.url}`);
	};
	if (activeUser?.type) {
		if (isImpersonating && Object.keys(cachedUser).length === 0) {
			redirectToHome();
		}

		// If user logged in

		if (activeUser?.type === "ADMIN") {
			// Allow access to everything
			return <>{children}</>;
		}
		if (activeUser?.type === "PRESCRIBER") {
			// Allow access to everything
			return <>{children}</>;
		}
		if (activeUser?.type === "MANAGER") {
			// Allow access to member and client
			if (urlType === "client" || urlType === "member") {
				return <>{children}</>;
			}
		}
		if (activeUser?.type === "MEMBER") {
			// Allow access to only member
			if (urlType === "member") {
				return <>{children}</>;
			}
		}

		if (activeUser?.type === "PROVIDER") {
			// Allow access to only provider
			if (urlType === "provider") {
				return <>{children}</>;
			}
		}

		// If they are somewhere they're not supposed to be, redirect to their home
		redirectToHome();
	}
	// Otherwise return login page
	return <Login />;
};

export default AuthGuard;
