import { Card, CardHeader, Divider, Grid, Typography } from "@mui/material";

// Use this editable table for all profile editing etc
// title = title for top of card
// editMode = if true, show form fields instead of text
// rows = the row data. Look at example of usage in app
// footer = optional jsx for footer of table. Can include buttons etc
const EditableTable = ({ title, editMode, rows, footer, header }) => {
	if (!rows || rows.length === 0) {
		throw new Error("EditableTable: rows must be defined");
	}
	const hiddenFields = rows.filter((r) => r?.field !== "hide");

	return (
		<Card>
			{title && (
				<>
					<CardHeader title={title || "Info"} />
					<Divider />
				</>
			)}

			{header}
			<Grid sx={{ py: 1, px: 1 }} container>
				{editMode ? (
					<>
						{hiddenFields.map((row) => {
							const FieldCell = () => (row.field ? row.field({ row }) : null);

							return (
								<Grid sx={{ px: 1, pt: 2, pb: 2 }} xs={row.width || 6}>
									<FieldCell row={row} />
								</Grid>
							);
						})}
					</>
				) : (
					<>
						{rows.map((row) => {
							const ValueCell = () => (row.value ? row.value({ row }) : null);

							return (
								<Grid sx={{ px: 1, py: 1 }} xs={6}>
									<Typography color="textSecondary" variant="overline">
										{row.label}
									</Typography>
									<Typography color="textPrimary" variant="body2">
										<ValueCell row={row} />
									</Typography>
								</Grid>
							);
						})}
					</>
				)}
			</Grid>

			{/* Optional footer JSX */}
			{footer}
		</Card>
	);
};

export default EditableTable;
