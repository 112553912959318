import { gql } from "@apollo/client";

const UpdateNotificationStatus = gql`
	mutation UpdateNotificationStatus($payload: updateNotificationInput) {
		updateNotificationStatus(payload: $payload) {
			... on notification {
				notificationId
				read
			}
			... on Error {
				message
			}
		}
	}
`;

export default UpdateNotificationStatus;
