import { gql } from "@apollo/client";

const GetEngagementUnsecured = gql`
	query GetEngagementUnsecured($payload: getEngagementInput) {
		getEngagementUnsecured(payload: $payload) {
			... on Engagement {
				engagementId
				offering {
					type
				}
				feedbackSubmitted
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetEngagementUnsecured;
