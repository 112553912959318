const createImage = (url) =>
	new Promise((resolve, reject) => {
		// eslint-disable-next-line no-undef
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous");
		image.src = url;
	});

const getCroppedImg = async (imageSrc, crop, type = "displayPic") => {
	const image = await createImage(imageSrc);
	// eslint-disable-next-line no-undef
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	const isDP = type === "displayPic";

	/* setting canvas width & height allows us to
    resize from the original image resolution */
	canvas.width = isDP ? 250 : 1500;
	canvas.height = isDP ? 250 : 562.5;

	ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);

	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			resolve(blob);
		}, "image/jpeg");
	});
};

export default getCroppedImg;
