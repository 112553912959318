/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from "@apollo/client";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Collapse,
	Dialog,
	Divider,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import { KeyboardArrowLeft, Save } from "@mui/icons-material";
import AddToPlaylist from "apollo/mutations/AddToPlaylist";
import CreatePlaylist from "apollo/mutations/CreatePlaylist";
import RemoveFromPlaylist from "apollo/mutations/RemoveFromPlaylist";
import GetClients from "apollo/queries/GetClients";
import GetPlaylists from "apollo/queries/GetPlaylists";
import FormField from "components/FormField/FormField";
import StandardButton from "components/StandardButton/StandardButton";
import useAuth from "hooks/useAuth";
import Minus from "icons/Minus";
import Plus from "icons/Plus";
import { useEffect, useState } from "react";

const PlaylistEditModal = ({ open, onClose, offeringId, offering }) => {
	const { activeUser } = useAuth();
	const [playlistName, setPlaylistName] = useState("");
	const [clientId, setClientId] = useState("");
	const [addPlaylist, setAddPlaylist] = useState(false);
	const [createPlaylist] = useMutation(CreatePlaylist);
	const { data, refetch } = useQuery(GetPlaylists, {
		variables: { payload: { clientId: activeUser?.clientId } },
		notifyOnNetworkStatusChange: true,
		nextFetchPolicy: "cache-first",
	});
	useEffect(() => {
		if (activeUser.type === "MANAGER") setClientId(activeUser.clientId);
	}, [activeUser, clientId]);
	const playlists = data?.getPlaylists || [];
	const togglePlaylistAdd = () => setAddPlaylist(!addPlaylist);
	const [loading, setLoading] = useState(false);
	const [addToPlaylist] = useMutation(AddToPlaylist);
	const [removeFromPlaylist] = useMutation(RemoveFromPlaylist);

	const clientsReq = useQuery(GetClients, {
		skip: activeUser.type !== "ADMIN",
	});
	const clients = clientsReq?.data?.getClients || [];

	const isInPlaylist = (playlist) => {
		if (playlist.offerings.find((o) => o.offeringId === offeringId)) return true;
		return false;
	};

	const togglePlaylist = async (type, playlistId, noLoad = false) => {
		try {
			if (!noLoad) setLoading(true);

			const payload = {
				variables: {
					payload: {
						offeringId,
						playlistId,
					},
				},
			};

			if (type === "add") {
				const create = await addToPlaylist(payload);

				if (create.data.addToPlaylist.__typename === "Error") {
					throw new Error(create.data.addToPlaylist.message);
				}
			} else {
				const create = await removeFromPlaylist(payload);

				if (create.data.removeFromPlaylist.__typename === "Error") {
					throw new Error(create.data.removeFromPlaylist.message);
				}
			}
			await refetch();
			if (!noLoad) setLoading(false);
		} catch (err) {
			if (!noLoad) setLoading(false);
		}
	};

	const handleCreatePlaylist = async () => {
		try {
			setLoading(true);
			const create = await createPlaylist({
				variables: {
					payload: {
						clientId,
						name: playlistName || "Untitled Playlist",
						description: `Playlist created by ${activeUser.firstName} ${activeUser.lastName}`,
					},
				},
			});
			const { playlistId } = create.data.createPlaylist;
			await togglePlaylist("add", playlistId, true);
			await refetch();
			setPlaylistName("");
			setClientId("");
			setAddPlaylist(false);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const shouldDisable = () => {
		if (activeUser.type === "MANAGER" && playlistName) return false;
		if (playlistName && clientId) return false;
		return true;
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<Card sx={{ width: 600 }}>
				<CardHeader title="Playlists" />
				{offering?.name && (
					<Box sx={{ px: 2, pb: 1 }}>
						<Typography>{offering.name}</Typography>
						<Typography variant="subtitle2">{offering?.provider?.name}</Typography>
					</Box>
				)}
				<Divider />

				<Box sx={{ pb: 2, px: 2 }}>
					<Collapse in={!addPlaylist}>
						<Box sx={{ overflow: "scroll", maxHeight: 400 }}>
							<List>
								{playlists.map((pl) => (
									<ListItem
										key={pl.playlistId}
										display="flex"
										flexDirection="row"
										sx={{
											borderRadius: 3,
											borderWidth: 1,
											borderStyle: "solid",
											justifyContent: "space-between",
											p: 1,
											mb: 1,
											pr: 3,
										}}>
										<Box>
											<Typography>{pl.name}</Typography>
											{activeUser.type === "ADMIN" && (
												<Typography variant="subtitle2">{pl?.client?.name || "-"}</Typography>
											)}
										</Box>

										{isInPlaylist(pl) ? (
											<Chip
												sx={{ cursor: "pointer" }}
												label="Remove"
												icon={<Minus />}
												onClick={() => togglePlaylist("sub", pl.playlistId, false)}
											/>
										) : (
											<Chip
												sx={{ cursor: "pointer" }}
												label="Add"
												color="secondary"
												icon={<Plus />}
												onClick={() => togglePlaylist("add", pl.playlistId, false)}
											/>
										)}
									</ListItem>
								))}
							</List>
						</Box>
						<Divider />
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="center"
							onClick={togglePlaylistAdd}
							sx={{
								borderRadius: 3,
								borderWidth: 1,
								borderStyle: "solid",
								p: 1.5,
								mb: 1,
								mt: 1,
								cursor: "pointer",
							}}>
							<Plus />
							<Typography>Create Playlist</Typography>
						</Box>
					</Collapse>
					<Collapse in={addPlaylist}>
						<Typography color="textSecondary" variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 200 }}>
							Create new playlist.
						</Typography>
						<FormField
							label="Playlist name"
							type="text"
							value={playlistName}
							onChange={(val) => setPlaylistName(val.target.value)}
						/>
						{activeUser.type === "ADMIN" && (
							<>
								<Box sx={{ mb: 2 }} />
								<FormField
									label="Client"
									type="select"
									options={
										clients?.length > 0
											? clients.map((c) => ({ label: c.name, value: c.clientId }))
											: []
									}
									onChange={(val) => setClientId(val?.value)}
								/>
							</>
						)}

						<Box sx={{ pt: 3 }} display="flex" flexDirection="row-reverse">
							<StandardButton
								onClick={handleCreatePlaylist}
								disabled={shouldDisable()}
								loading={loading}
								buttonStyle="primary"
								icon={<Save />}
								sx={{ mr: 0 }}
								text="Save & Add"
							/>
							<StandardButton
								onClick={togglePlaylistAdd}
								buttonStyle="secondary"
								icon={<KeyboardArrowLeft />}
								text="Back"
							/>
						</Box>
					</Collapse>
				</Box>
			</Card>
		</Dialog>
	);
};

export default PlaylistEditModal;
