import { styled } from "@mui/styles";
import Hidden from "components/common/Hidden";
import Navbar from "components/Navbar/NavBar";
import AuthContext from "contexts/AuthContext";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import ImpersonationCard from "./ImpersonationCard";
import LogoBox from "./LogoBox";
import { adminMenu, clientMenuMobile, memberMenuMobile, providerMenu } from "./menus";
import SideBar from "./SideBar";

const StandardLayoutRoot = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: "flex",
	height: "100%",
	overflow: "hidden",
	width: "100%",
}));

const StandardLayoutWrapper = styled("div")(() => ({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
	paddingTop: "64px",
}));

const StandardLayoutContainer = styled("div")({
	display: "flex",
	flex: "1 1 auto",
	overflow: "hidden",
});

const StandardLayoutContent = styled("div")({
	flex: "1 1 auto",
	height: "100%",
	overflow: "auto",
	position: "relative",
	WebkitOverflowScrolling: "touch",
});

const StandardLayout = ({ type = "client" }) => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
	const { activeUser, cachedUser, stopImpersonation } = useContext(AuthContext);

	return (
		<>
			<StandardLayoutRoot>
				{/* RENDERS THE CORRECT NAV FOR USER TYPE */}
				<Navbar type={type} onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />

				{type === "client" && (
					<Hidden rules="lgUp">
						<SideBar
							menuArr={clientMenuMobile}
							onMobileClose={() => setIsSidebarMobileOpen(false)}
							openMobile={isSidebarMobileOpen}
							topBox={
								<LogoBox
									firstLine={activeUser?.clientAccount?.name}
									imageUrl={activeUser?.clientAccount?.displayPic}
								/>
							}
						/>
					</Hidden>
				)}

				{type === "admin" && (
					<Hidden rules="lgUp">
						<SideBar
							menuArr={adminMenu}
							onMobileClose={() => setIsSidebarMobileOpen(false)}
							openMobile={isSidebarMobileOpen}
						/>
					</Hidden>
				)}

				{type === "member" && (
					<Hidden rules="lgUp">
						<SideBar
							topBox={
								<LogoBox
									firstLine={activeUser?.clientAccount?.name}
									imageUrl={activeUser?.clientAccount?.displayPic}
									secondLine="Sponsored by"
								/>
							}
							menuArr={memberMenuMobile}
							onMobileClose={() => setIsSidebarMobileOpen(false)}
							openMobile={isSidebarMobileOpen}
						/>
					</Hidden>
				)}

				{type === "provider" && (
					<Hidden rules="lgUp">
						<SideBar
							topBox={
								<LogoBox
									firstLine={`${activeUser.firstName} ${activeUser.lastName}`}
									secondLine={activeUser.title}
									imageUrl={activeUser.displayPic}
								/>
							}
							menuArr={providerMenu}
							onMobileClose={() => setIsSidebarMobileOpen(false)}
							openMobile={isSidebarMobileOpen}
						/>
					</Hidden>
				)}

				<StandardLayoutWrapper>
					<StandardLayoutContainer>
						<StandardLayoutContent id="main-container">
							{Object.keys(cachedUser).length > 0 && (
								<ImpersonationCard activeUser={activeUser} stopImpersonation={stopImpersonation} />
							)}

							<Outlet />
						</StandardLayoutContent>
					</StandardLayoutContainer>
				</StandardLayoutWrapper>
			</StandardLayoutRoot>
		</>
	);
};

export default StandardLayout;
