import moment from "moment";

const formatDate = (date, includeTime) => {
	try {
		if (includeTime) {
			return moment(date).format("Do MMM YYYY @ h:mm a");
		}
		return moment(date).format("Do MMM YYYY");
	} catch (error) {
		return "ERR";
	}
};

export default formatDate;
