import { gql } from "@apollo/client";

const CreateAnalyticEvent = gql`
	mutation CreateAnalyticEvent($payload: createAnalyticEventInput) {
		createAnalyticEvent(payload: $payload) {
			... on AnalyticEvent {
				analyticEventId
			}
			... on Error {
				message
			}
		}
	}
`;

export default CreateAnalyticEvent;
