// Types for dropdown menu
const providerTypes = [
	{ label: "Coach", value: "COACH" },
	{ label: "Course", value: "COURSE" },
	{ label: "Service", value: "SERVICE" },
	{ label: "Subscription", value: "SUBSCRIPTION" },
	{ label: "Event", value: "EVENT" },
];

export default providerTypes;
