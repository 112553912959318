const images = [
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_126455034-min.jpeg?alt=media&token=774699fc-0a06-433c-8455-74e826bd0698",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_245573997-min.jpeg?alt=media&token=3a1f0d38-6e2e-4e80-b563-da638bb11523",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_286390310-min.jpeg?alt=media&token=82a484a5-7c53-4976-b009-b1ebd065d383",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_302820267-min.jpeg?alt=media&token=ad78a398-80b6-45ea-b3f2-80ab392d1cea",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_51349670-min.jpeg?alt=media&token=2acb003c-d967-4186-97f2-e6a2bb07e4c2",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_227424015-min.jpeg?alt=media&token=da9304e8-c387-49bf-983f-48f3706cdc63",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_213167172-min.jpeg?alt=media&token=81a008f1-d883-4452-ad06-1456e6ef9fec",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_297261446-min.jpeg?alt=media&token=fd50f1c4-30c0-4c9f-87cd-729377526f99",
	"https://firebasestorage.googleapis.com/v0/b/careerbasev3-development.appspot.com/o/image-service%2FimageLib%2FAdobeStock_437821135-min.jpeg?alt=media&token=5c723ae4-9b5d-410c-8156-35eb4e0a5b8a",
];

const getRandomCover = () => {
	const num = Math.floor(Math.random() * 9);
	return images[num];
};

export default getRandomCover;
