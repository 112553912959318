import { gql } from "@apollo/client";

const GetFavorites = gql`
	query GetFavorites($payload: getFavoritesInput) {
		getFavorites(payload: $payload) {
			... on Offering {
				offeringId
				providerId
				provider {
					providerId
					name
					type
					displayPic
				}
				name
				availability
				description
				costPrice
				salePrice
				delivery
				duration
				workload
				updatedAt
				createdAt
				summary
				displayPic
				coverPic
				data
				purchased
				type
			}
			... on Error {
				message
			}
		}
	}
`;

export default GetFavorites;
