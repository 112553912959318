const locationsList = [
	{ label: "Online", value: "ONLINE" },
	{ label: "Sydney", value: "SYDNEY_AU" },
	{ label: "Parramatta", value: "PARRAMATTA_AU" },
	{ label: "Melbourne", value: "MELBOURNE_AU" },
	{ label: "Brisbane", value: "BRISBANE_AU" },
	{ label: "Gold Coast", value: "GOLD_COAST_AU" },
	{ label: "Adelaide", value: "ADELAIDE_AU" },
	{ label: "Perth", value: "PERTH_AU" },
	{ label: "Darwin", value: "DARWIN_AU" },
	{ label: "Alice Springs", value: "ALICE_SPRINGS_AU" },
	{ label: "Canberra", value: "CANBERRA_AU" },
	{ label: "Townsville", value: "TOWNSVILLE_AU" },
	{ label: "Cairns", value: "CAIRNS_AU" },
	{ label: "Newcastle", value: "NEWCASTLE_AU" },
	{ label: "Wellington", value: "WELLINGTON_NZ" },
	{ label: "Auckland", value: "AUCKLAND_NZ" },
	{ label: "Singapore", value: "SINGAPORE_SG" },
	{ label: "Los Angeles", value: "LOS_ANGELES_US" },
	{ label: "San Francisco", value: "SAN_FRANCISCO_US" },
	{ label: "San Diego", value: "SAN_DIEGO_US" },
	{ label: "New York", value: "NEW_YORK_US" },
	{ label: "Miami", value: "MIAMI_US" },
	{ label: "Austin", value: "AUSTIN_US" },
	{ label: "Salt Lake City", value: "SALT_LAKE_CITY_US" },
	{ label: "Seattle", value: "SEATTLE_US" },
	{ label: "London", value: "LONDON_UK" },
	{ label: "Leeds", value: "LEEDS_UK" },
	{ label: "Sheffield", value: "SHEFFIELD_UK" },
	{ label: "Toronto", value: "TORONTO_CA" },
	{ label: "Montreal", value: "MONTREAL_CA" },
	{ label: "Vancouver", value: "VANCOUVER_CA" },
];

export default locationsList;
